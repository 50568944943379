import InputEntity from '@/lib/formFactory/inputEntity';
import InputSuggestionEntity from '@/lib/formFactory/inputSuggestionsEntity';

export default class EntityModel {
    model: any;

    constructor() {
        const nameEntity = new InputEntity('name');
        nameEntity.setTitle('Название города');
        nameEntity.setRequired(true);
        nameEntity.initLocalization();

        const countryEntity = new InputSuggestionEntity('country');
        countryEntity.setTitle('Страна');
        countryEntity.setRequired(true);

        const sortEntity = new InputEntity('sort');
        sortEntity.setTitle('Порядок сортировки');

        const timezoneEntity = new InputEntity('timezone');
        timezoneEntity.setTitle('Часовой пояс');
        timezoneEntity.setRequired(true);
        timezoneEntity.setMask({
            mask: '+99:00',
            showMaskOnHover: false,
        });
        timezoneEntity.setValidation({
            empty: 'Поле не заполнено',
            regex: 'Часовой пояс введен неверно',
        });

        this.model = {
            name: nameEntity.entity[nameEntity.entityName],
            country: countryEntity.entity[countryEntity.entityName],
            sort: sortEntity.entity[sortEntity.entityName],
            timezone: timezoneEntity.entity[timezoneEntity.entityName],
        };
    }
}
