
import { Vue, Component } from 'vue-property-decorator';
import ImportEntity from '@/admin/store/settings/import/entity';
import SettingsModule from '@/admin/store/settings';

import Loading from '@/components/Loading.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import BtnDefault from '@/components/BtnDefault.vue';
import { tabsNavInterface } from '@/interfaces/tabs';
import { importModelInterface } from '@/interfaces/models/import.interface';

@Component({
    components: {
        Loading,
        TitleReturn,
        TabsNav,
        BtnDefault,
    },
})
export default class ImportCardComponent extends Vue {
    title = 'Импорт с сервера';
    componentCounter = 0;

    get model(): importModelInterface {
        return ImportEntity.model;
    }

    get isLoading(): boolean {
        return ImportEntity.isLoading;
    }

    get tabsNav(): tabsNavInterface {
        return SettingsModule.tabsNav;
    }

    get currentTab(): string {
        return SettingsModule.allTabs.import.id;
    }

    redirect(): void {
        this.$router.push({ name: 'settings_import' });
    }

    created(): void {
        SettingsModule.initTabsItems();
        ImportEntity.init(+this.$route.params.importId);
    }

    beforeDestroy(): void {
        ImportEntity.reset();
    }
}
