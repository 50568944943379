
import { Component, Mixins, Watch } from 'vue-property-decorator';

import CardMixin from './CardMixin';

import IconFont from '@/components/icons/IconFont.vue';
import TableAction from '@/components/table/TableActionPainter.vue';

import SettingsPainterModule from '@/admin/store/settings/auctions/painter';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { declinateWord, formatNumberSpaced, formatRemoveSpaces } from '@/lib/utils/Utils';

import { textHours, textPaintings, textThings } from '@/admin/store/settings/auctions/texts';

import Inputmask from 'inputmask';
import { floatRegExp, numberRegExp } from '@/constants';

@Component({
    mixins: [CardMixin],
    components: {
        IconFont,
        TableAction,
    },
})
export default class SettingsAuctionsRulesPainter extends Mixins(CardMixin) {
    componentCounter = 0;
    termInvoicePayment = this.findName('termInvoicePayment');
    platformCommission = this.findName('platformCommission');
    exposeTime = this.findName('exposeTime');
    productivityPainterNewItemStatus = false;
    table: any = {
        rows: [],
    };

    get exposeTimeText(): string {
        return String(this.exposeTime.value).length ? declinateWord(this.exposeTime.value, textHours) : textHours[2];
    }

    get termInvoicePaymentText(): string {
        return String(this.termInvoicePayment.value).length
            ? declinateWord(this.termInvoicePayment.value, textHours)
            : textHours[2];
    }

    get reExposeTimeText(): string {
        return String(this.productivitySettings[this.productivityIndex].reExposeTime).length
            ? declinateWord(this.productivitySettings[this.productivityIndex].reExposeTime, textHours)
            : textHours[2];
    }

    get productivityRangeLimitText(): string {
        return String(this.productivitySettings[this.productivityIndex].range.to).length
            ? declinateWord(+this.productivitySettings[this.productivityIndex].range.to, textPaintings)
            : textPaintings[2];
    }

    get limitText(): string {
        return String(this.productivitySettings[this.productivityIndex].limit).length
            ? declinateWord(+this.productivitySettings[this.productivityIndex].limit, textThings)
            : textThings[2];
    }

    get productivityIndex(): number {
        return SettingsPainterModule.productivityIndex;
    }

    async deleteItemProductivityPainter(deleteIndex: number): Promise<void> {
        SettingsPainterModule.deleteProductivityByIndex(deleteIndex);
        SettingsPainterModule.updateProductivityIndex(0);
    }

    get productivity() {
        const productivity: any = [];

        Object.keys(this.productivitySettings).forEach((key: string) => {
            productivity.push({
                id: key,
                from: this.productivitySettings[+key].range.from,
                to: this.productivitySettings[+key].range.to,
            });
        });

        return [...productivity];
    }

    get productivitySettings() {
        return SettingsPainterModule.productivity;
    }

    get saveCounter() {
        return SettingsPainterModule.saveCounter;
    }

    getNumberFormatted(value: string): string {
        return formatNumberSpaced(value);
    }

    updateExposeTime(event: InputEvent): void {
        this.exposeTime.value = +formatRemoveSpaces((event.target as HTMLInputElement).value);
    }

    updateTermInvoicePayment(event: InputEvent): void {
        this.termInvoicePayment.value = +formatRemoveSpaces((event.target as HTMLInputElement).value);
    }

    deleteItem(params: { action: string; id: number }) {
        SettingsPainterModule.deleteCategoryById(params.id);
    }

    handleInput(params: { action: string; data: { rowId: number; fieldName: string; value: string } }) {
        SettingsPainterModule.updateCategoryValue(params.data);
    }

    handleCheckbox(params: { action: string; data: { fieldName: string; rowId: number; bool: boolean } }) {
        SettingsPainterModule.updateCategoryCheckboxValue(params.data);
    }

    updateProductivityLimit(event: InputEvent) {
        if (this.productivityIndex == null) {
            return;
        }

        SettingsPainterModule.updateProductivityLimit({
            limit: formatRemoveSpaces((event.target as HTMLInputElement).value),
            id: this.productivityIndex,
        });
    }

    updateProductivityReExposeTime(event: InputEvent) {
        if (this.productivityIndex == null) {
            return;
        }

        SettingsPainterModule.updateProductivityReExposeTime({
            time: formatRemoveSpaces((event.target as HTMLInputElement).value),
            id: this.productivityIndex,
        });
    }

    async updateProductivityRangeTo(event: InputEvent) {
        if (this.productivityIndex == null) {
            return;
        }

        const value = (event.target as HTMLInputElement).value;
        await SettingsPainterModule.updateProductivityRangeTo({
            value: +formatRemoveSpaces(value),
            id: this.productivityIndex,
        });
        await this.$nextTick();
        this.componentCounter++;
    }

    addEmptyRow() {
        if (this.productivityIndex == null) {
            return;
        }

        SettingsPainterModule.addEmptyRow();
    }

    selectCategory(params: { selected: InputSuggestionItem; rowId: number }) {
        if (this.productivityIndex == null) {
            return;
        }

        SettingsPainterModule.selectCategory({
            id: this.productivityIndex,
            rowId: params.rowId,
            selected: params.selected,
        });
    }

    findName(name: string) {
        const find = this.$store.state.settingPainter.settings.find((item: any) => {
            return item.name === name;
        });

        if (name === 'productivity') {
            return { value: JSON.parse(find.value) };
        }

        return Object.assign({}, find);
    }

    findJsonName(name: string) {
        const find = this.findName(name);

        find.type = 'json';
        find.value = JSON.parse(find.value);

        return find;
    }

    displayProductivityById(id: string) {
        SettingsPainterModule.updateProductivityIndex(+id);
    }

    @Watch('saveCounter')
    onChangeSaveCounter(): void {
        SettingsPainterModule.setItem({
            name: 'termInvoicePayment',
            value: this.termInvoicePayment.value,
        });
        SettingsPainterModule.setItem({ name: 'platformCommission', value: this.platformCommission.value });
        SettingsPainterModule.setItem({ name: 'exposeTime', value: this.exposeTime.value });
    }

    initMask() {
        const settingsMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputs = this.$el.getElementsByClassName('j_number_mask_wrapper');

        for (const item of Object.values(inputs)) {
            settingsMask.mask(item.getElementsByTagName('input')[0]);
        }

        const floatMask = new Inputmask({ regex: floatRegExp, showMaskOnHover: false });
        const inputsFloat = this.$el.getElementsByClassName('j_float_mask_wrapper');

        for (const item of Object.values(inputsFloat)) {
            floatMask.mask(item as HTMLElement);
        }
    }

    created() {
        this.titlesTablePainter.push({
            id: '_actions',
            name: 'add',
            visible: true,
        });
    }

    mounted() {
        this.initMask();
    }
}
