import { PainterProductivityItemInterface } from '@/interfaces/settingsAuctions';
import { SettingsPainterProductivityInterface } from '@/interfaces/settingsPainter';

/**
 * @param {[string, PainterProductivityItemInterface][]} result productivity entries array
 * @param {SettingsPainterProductivityInterface} value productivity entry
 * @param {number} index productiviry entry order index
 * @returns {[string, PainterProductivityItemInterface][]} reduced productivity entries array
 */
export const productivityValueReducer = (
    result: [string, PainterProductivityItemInterface][],
    value: SettingsPainterProductivityInterface,
    index: number,
): [string, PainterProductivityItemInterface][] => {
    if (value.from == null && index === 0) {
        value.from = 0;
    }

    if (value.from == null) {
        return result;
    }

    return [
        ...result,
        [
            String(value.id),
            {
                range: {
                    from: value.from,
                    to: value.to as number,
                },
                limit: 0,
                reExposeTime: 0,
                categories: [],
            },
        ],
    ];
};
