import { Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import PageBaseModule from '@/admin/store/page';
import store from '@/admin/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { getSettingAuction } from '@/admin/api/settingAuction';
export const MODULE_NAME = 'settingGallery';

import type { SettingsAuctionsType } from '@/interfaces/settingsAuctions';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SettingsGallery extends PageBaseModule {
    settings: SettingsAuctionsType[] = [];
    saveCounter = 0;

    constructor(module: SettingsGallery) {
        super(module);
        const page = new PageEntity();

        page.setTitle('Настройка аукционов');
        this.pageSettings = page.values;
    }

    @Mutation
    SET_SAVE_COUNTER() {
        this.saveCounter++;
    }

    @Mutation
    SET_SETTINGS(value: SettingsAuctionsType[]) {
        this.settings = value;
    }

    @Mutation
    SET_ITEM(payload: { name: string; value: any }) {
        this.settings.forEach((item: any) => {
            if (payload.name === item.name) {
                item.value = payload.value;
            }
        });
    }

    @Action({ rawError: true })
    async init() {
        try {
            const result = await getSettingAuction('?section=CommonGallery');

            this.context.commit('SET_SETTINGS', result);
        } catch (err) {
            return err;
        }
    }

    @Action({ rawError: true })
    async setItem(value: any) {
        this.context.commit('SET_ITEM', value);
    }

    @Action({ rawError: true })
    async setSaveCounter() {
        this.context.commit('SET_SAVE_COUNTER');
    }
}

export default getModule(SettingsGallery);
