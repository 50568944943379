import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import EntityBaseModule from '@/admin/store/entity';
import EntityModel from './entityModel';

import store from '@/admin/store';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import { createSpecialtyCard, deleteSpecialtyCard, editSpecialtyCard, getSpecialtyCard } from '@/admin/api/specialty';
import { formatCreatedAt, getApiErrorMessage, showError } from '@/lib/utils/Utils';
import RedirectHandlerModule from '@/admin/store/modules/redirectHandler';
import SiteModule from '@/admin/store/site';

export const MODULE_NAME = 'settingsSpecialtyEntity';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SettingsSpecialtyModule extends EntityBaseModule {
    cardId: number | null = null;
    model: any;
    specialtyName = '';
    titleReturnData = {
        edit: {
            icon: 'user',
            title: this.specialtyName,
        },
        create: {
            icon: '',
            title: 'Добавление специализации',
        },
    };

    constructor(module: SettingsSpecialtyModule) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
    }

    @Mutation
    SET_CARD_ID(id: number | null) {
        this.cardId = id;
    }

    @Mutation
    SET_SPECIALTY_NAME(name: string) {
        this.specialtyName = name;
        this.titleReturnData.edit.title = name;
    }

    @Mutation
    SET_TABLE_SORT(sort: string) {
        this.model.sort.value = sort;
    }

    @Action({ rawError: true })
    async initCardById(specialtyId: number | string) {
        const result = await getSpecialtyCard(specialtyId);

        this.setInfo(result);
    }

    @Action({ rawError: true })
    setInfo(data: any) {
        if (!data) {
            return;
        }

        this.context.commit('SET_CARD_ID', data.id);
        this.context.commit('SET_NAME', data.name ? data.name : '');
        this.context.commit('SET_SPECIALTY_NAME', data.name ? data.name : '');
        this.context.commit('SET_TABLE_SORT', data.sort ? data.sort : '');
        this.context.commit('SET_IS_ACTIVE', data.isActive ? data.isActive : false);
        this.context.commit('SET_CREATED_AT', data.createdAt ? formatCreatedAt(data.createdAt) : '');
    }

    @Action({ rawError: true })
    updateName(name: string) {
        if (name !== '') {
            this.context.commit('RESET_ERROR_BY_FIELD', 'name');
        }

        this.context.commit('SET_NAME', name);
    }

    @Action({ rawError: true })
    updateTableSort(params: string) {
        this.context.commit('SET_TABLE_SORT', params);
    }

    @Action({ rawError: true })
    validate() {
        let isError = false;
        this.context.commit('RESET_ERROR_BY_FIELD', 'name');

        this.setValidateState({ index: 'name', field: this.model.name });

        if (!this.model.name.value) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: 'name', message: '' });
            isError = true;
        }

        return isError;
    }

    @Action({ rawError: true })
    prepareRequestData() {
        const data: any = {
            name: this.model.name.value,
            sort: this.model.sort.value ? this.model.sort.value : 0,
            isActive: this.model.isActive.list[0].checked ? 1 : 0,
        };

        return data;
    }

    @Action({ rawError: true })
    async sendEdit() {
        const isError = await this.validate();

        if (isError === true) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const formData = await this.prepareRequestData();
            let result: any;

            if (this.cardId != null) {
                result = await editSpecialtyCard(this.cardId, formData);
            }

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            showError(getApiErrorMessage(error));
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    async sendAdd(redirect: any = null) {
        const isError = await this.validate();

        if (isError === true) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const formData = await this.prepareRequestData();
            const result: any = await createSpecialtyCard(formData);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                RedirectHandlerModule.changeUrl(redirect);
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            showError(getApiErrorMessage(error));
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    async sendDelete() {
        if (!this.cardId) {
            return;
        }

        try {
            const result = (await deleteSpecialtyCard(String(this.cardId))) as any;

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });

                return true;
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    reset() {
        this.context.commit('SET_CARD_ID', null);
        this.context.commit('SET_NAME', '');
        this.context.commit('SET_SPECIALTY_NAME', '');
        this.context.commit('SET_TABLE_SORT', '');
        this.context.commit('SET_IS_ACTIVE', false);
        this.context.commit('SET_CREATED_AT', '');

        SiteModule.SET_IS_BLOCK(false);

        this.resetErrorState();
    }

    @Action({ rawError: true })
    resetErrorState() {
        this.context.commit('RESET_ERROR_BY_FIELD', 'name');
    }
}

export default getModule(SettingsSpecialtyModule);
