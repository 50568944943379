
import { Mixins, Component, Prop } from 'vue-property-decorator';

import TableTitle from './_tableTitle.vue';
import IconFont from '@/components/icons/IconFont.vue';
import PaginationBlock from '@/components/Pagination.vue';
import CheckboxCustom from '@/components/form/Checkbox.vue';

import tableMixin from './tableMixin';

import moment from 'moment/moment.js';
import Inputmask from 'inputmask';
import { floatRegExp, numberRegExp } from '@/constants';

moment.locale('ru');

@Component({
    components: {
        TableTitle,
        IconFont,
        PaginationBlock,
        CheckboxCustom,
    },
})
export default class TableActionsComponent extends Mixins(tableMixin) {
    @Prop() readonly depend!: any;
    @Prop() readonly categories!: any;
    @Prop() readonly name!: string;

    checkboxUpdate(val: any) {
        if (!Array.isArray(this.rows)) return;

        let findIndex = this.rows.findIndex((item) => {
            return item.id === val.name.id;
        });

        this.rows[findIndex][val.data_value].checked = val.bool;

        this.$emit('validate-table', { value: this.rows[findIndex], item: val });
    }

    mounted(): void {
        const numberMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputsNumber = this.$el.getElementsByClassName('j_number_mask_wrapper');

        const decimalMask = new Inputmask({ regex: floatRegExp, showMaskOnHover: false });
        const inputsDecimal = this.$el.getElementsByClassName('j_decimal_mask_wrapper');

        for (const item of Object.values(inputsNumber)) {
            numberMask.mask(item as HTMLElement);
        }

        for (const item of Object.values(inputsDecimal)) {
            decimalMask.mask(item as HTMLElement);
        }
    }
}
