
import { Component, Vue } from 'vue-property-decorator';

import CountryCitiesModule from '@/admin/store/settings/country/entityCity';

import Loading from '@/components/Loading.vue';
import TableBlock from '@/components/table/Table.vue';
import TitleReturn from '@/components/TitleReturn.vue';

import { PageInterface } from '@/lib/layouts/page/page.interface';

@Component({
    components: { TableBlock, TitleReturn, Loading },
})
export default class CountryCitiesTableComponent extends Vue {
    get isLoading(): boolean {
        return CountryCitiesModule.isLoading;
    }

    get settings(): PageInterface {
        return CountryCitiesModule.pageSettings;
    }

    get paginationItems(): { items: string } {
        return { items: this.settings.pageAmountItems };
    }

    get paginationAction(): (number: string) => void {
        return CountryCitiesModule.updatePage;
    }

    selectAmount(items: string): void {
        this.settings.pageAmountItems = items;
        CountryCitiesModule.getList();
    }

    tableAction(params: { action: string; field: string; sort: string }): void {
        if (params.action === 'sort') {
            CountryCitiesModule.sort({ field: params.field, sort: params.sort });
        }
    }

    toAdd(): void {
        if (this.$route.name !== 'settings_country_create') {
            this.$router.push({
                name: 'settings_city_create',
                params: { countryId: this.$route.params.countryId.toString() },
            });
        }
    }

    created(): void {
        CountryCitiesModule.init(this.$route.params.countryId);
    }
}
