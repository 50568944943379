
import Layout from '@/admin/layouts/Default.vue';
import CategoryMain from './category/Main.vue';
import CategoryCard from './category/card/Main.vue';
import CityMain from './city/Main.vue';
import CityCard from './city/card/Main.vue';
import CountryMain from './country/Main.vue';
import CountryCard from './country/card/Main.vue';
import SpecialtyMain from './specialty/Main.vue';
import SpecialtyCard from './specialty/_card.vue';
import AuctionsMain from './auctions/Main.vue';
import CurrenciesMain from './currencies/Index.vue';
import ColorsMain from './colors/Index.vue';
import ImportMain from './import/Main.vue';
import ImportCard from './import/Card.vue';

export default {
    components: {
        Layout,
        CategoryMain,
        CategoryCard,
        CityMain,
        CityCard,
        CountryMain,
        CountryCard,
        SpecialtyMain,
        SpecialtyCard,
        AuctionsMain,
        CurrenciesMain,
        ColorsMain,
        ImportMain,
        ImportCard,
    },
};
