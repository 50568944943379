
import { Component, Watch, Mixins } from 'vue-property-decorator';
import CheckboxCustom from '@/components/form/Checkbox.vue';
import TableAction from '@/components/table/TableAction.vue';
import IconFont from '@/components/icons/IconFont.vue';
import Inputmask from 'inputmask';

import SettingsActionModule from '@/admin/store/settings/auctions';

import CardMixin from './CardMixin';
import { floatRegExp } from '@/constants';
import { formatNumberSpaced, formatRemoveSpaces } from '@/lib/utils/Utils';

export interface FollowCommissionTableRow {
    id: number | string;
    from: number | string;
    to: number | string;
    fix: number | string;
    percent: number;
    _actions: string[];
}

@Component({
    mixins: [CardMixin],
    components: {
        CheckboxCustom,
        TableAction,
        IconFont,
    },
})
export default class SettingsAuctionsCommon extends Mixins(CardMixin) {
    financeCommission = this.findName('financeCommission');
    fineUnpaidOrder = this.findJsonName('fineUnpaidOrder');
    processingManagementPercent = this.findName('processingManagementPercent');
    followCommissions = this.setFollowCommissions('followCommissions');
    gdpr = this.findName('GDPR');

    table = {
        name: 'rightToFollow',
        rows: this.followCommissions.value,
    };

    setFollowCommissions(name: string) {
        let followCommissions = this.findJsonName(name);

        followCommissions.value = followCommissions.value.map((item: any, index: number) => {
            return {
                id: index,
                from: formatNumberSpaced(item.from ?? 0),
                to: formatNumberSpaced(item.to ?? item.from + 1),
                fix: formatNumberSpaced(item.fix ?? 0),
                percent: item.percent ?? 0,
                _actions: ['delete'],
            };
        });

        return followCommissions;
    }

    checkboxUpdate(val: any) {
        let $this: any = this;
        $this[val.name].value = val.bool;
    }

    checkboxUpdateFine(val: any) {
        if (val.name === 'percent') {
            this['fineUnpaidOrder']['value']['percent'] = true;
            this['fineUnpaidOrder']['value']['fix'] = false;
        } else {
            this['fineUnpaidOrder']['value']['percent'] = false;
            this['fineUnpaidOrder']['value']['fix'] = true;
        }

        this.depend++;
    }

    addItem() {
        this.table.rows.push({
            id: this.table.rows.length ? this.table.rows.length + 1 : 0,
            from: 0,
            to: 0,
            fix: 0,
            percent: 0,
            _actions: ['delete'],
        });

        this.validateTable();
    }

    validateTable(): void {
        let prevRow: FollowCommissionTableRow;
        this.table.rows.map((row: FollowCommissionTableRow, index: number) => {
            row.from = formatRemoveSpaces(row.from as string);
            row.to = formatRemoveSpaces(row.to as string);
            row.fix = formatRemoveSpaces(row.fix as string);

            row.from = prevRow ? +formatRemoveSpaces(prevRow.to as string) + 1 : row.from;

            if (index !== this.table.rows.length - 1) {
                row.to = +row.from >= +row.to ? +row.from + 1 : row.to;
            }

            row.fix = +row.fix < 0 ? 0 : row.fix;

            if (+row.percent < 0) {
                row.percent = 0;
            }

            if (+row.percent > 100) {
                row.percent = 100;
            }

            row.from = formatNumberSpaced(row.from);
            row.to = formatNumberSpaced(row.to);
            row.fix = formatNumberSpaced(row.fix);

            prevRow = row;

            return row;
        });
    }

    getNumberFormatted(value: string): string {
        return formatNumberSpaced(value);
    }

    updateFineUnpaidOrder(event: InputEvent) {
        this.fineUnpaidOrder.value.fixValue = formatRemoveSpaces((event.target as HTMLInputElement).value);
    }

    updateFineUnpaidOrderPercent(val: any) {
        if (+val.percentValue < 0) {
            val.percentValue = 0;
        }

        if (+val.percentValue > 100) {
            val.percentValue = 100;
        }
    }

    @Watch('saveCounter')
    onChangeSaveCounter() {
        SettingsActionModule.setItem({ name: 'financeCommission', value: this.financeCommission.value });
        SettingsActionModule.setItem({ name: 'fineUnpaidOrder', value: JSON.stringify(this.fineUnpaidOrder.value) });
        SettingsActionModule.setItem({
            name: 'processingManagementPercent',
            value: this.processingManagementPercent.value,
        });
        SettingsActionModule.setItem({
            name: 'followCommissions',
            value: JSON.stringify(
                this.table.rows.map((row: FollowCommissionTableRow) => {
                    return {
                        ...row,
                        from: formatRemoveSpaces(row.from as string),
                        to: formatRemoveSpaces(row.to as string),
                        fix: formatRemoveSpaces(row.fix as string),
                    };
                }),
            ),
        });
        SettingsActionModule.setItem({ name: 'GDPR', value: this.gdpr.value });
    }

    mounted() {
        const floatMask = new Inputmask({ regex: floatRegExp, showMaskOnHover: false });
        const inputsFloat = this.$el.getElementsByClassName('j_float_mask_wrapper');

        for (const item of Object.values(inputsFloat)) {
            floatMask.mask(item as HTMLElement);
        }
    }
}
