
import { InputSuggestionMultipleItem } from '@/lib/formFactory/inputSuggestionMultiple.interface';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'TreeNode',
    components: { TreeNode },
})
export default class TreeNode extends Vue {
    @Prop() node!: InputSuggestionMultipleItem;
    @Prop({ default: 0 }) level!: number;
    @Prop() list!: any;
    @Prop() parent!: any;
    @Prop() isSelect!: boolean;

    select(item: InputSuggestionMultipleItem): void {
        if (this.isSelect) {
            this.$emit('click', item);

            return;
        }

        if (this.selectedChildren && item?.children?.length && this.level === 0) {
            const items = this.allChildren(item);

            items.forEach((node) => {
                node.selected = true;

                this.$emit('click', node);
            });

            if (!this.selectedChildren) {
                this.$emit('remove-level');
            }


            this.$emit('click', item);

            return;
        }

        if (this.level >= 1 && !item.selected && this.parent) {
            this.$emit('click', { id: this.parent.id, value: this.parent.value, selected: false });

            this.$emit('click', item);

            return;
        }

        if (this.level >= 1 && item.selected && this.parent) {
            const children = this.allChildren(this.parent);
            const selected = children.filter((node: any) => {
                return !!node.selected;
            });

            if (selected.length <= 1) {
                children.forEach((node) => {
                    node.selected = true;

                    this.$emit('click', node);
                });

                if (!this.selectedChildren) {
                    this.$emit('remove-level');

                    this.clearParent(item);
                }
            } else {
                this.$emit('click', item);

                this.clearParent(item);
            }

            return;
        }

        this.$emit('click', item);
    }

    get children() {
        return this.allChildren(this.node);
    }

    get selectedChildren(): boolean {
        const result = this.children.find((item: { selected: boolean }) => {
            return item.selected;
        });

        return Boolean(result);
    }

    clearParent(item: any) {
        const parentId = this.findParent(this.list, [item.id])[0]?.id;
        const parent = this.list.find((item: { id: number }) => {
            return item.id === parentId;
        });

        const selected = this.allChildren(parent).filter((item: { selected: boolean }) => {
            return item.selected;
        });

        if (!selected.length) {
            this.$emit('click', { id: parent.id, value: parent.value, selected: true });
        }
    }

    findParent(data: any, ids: any) {
        return data.reduce((acum: any, item: any)=> {
            if (ids.includes(item.id)) {
                acum.push(item);

                return acum;
            }

            if (!item.children) return acum;

            const ch = this.findParent(item.children, ids);

            if (!ch.length) return acum;

            const newItem = Object.assign({}, item);
            newItem.children = ch;
            acum.push(newItem);

            return acum;
        }, []);
    }

    allChildren(value: any) {
        const children = value?.children;
        const result = [];
        const all: any = [];

        if (!children || !children.length) {
            return [];
        }

        result.push(...children);

        children.forEach((value: any) => {
            all.push(...this.allChildren(value));
        });

        return [...result, ...all];
    }

    removeLevel() {
        this.$emit('click', { id: this.node.id, value: this.node.value, selected: true });
    }
}
