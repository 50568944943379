
import { Component, Vue } from 'vue-property-decorator';
import TitleReturn from '@/components/TitleReturn.vue';

import BtnDefault from '@/components/BtnDefault.vue';
import InputBlock from '@/components/form/InputMain.vue';
import SelectCustom from '@/components/select/SelectCustom.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import CheckboxDefault from '@/components/form/Checkbox.vue';

import Inputmask from 'inputmask';
import SettingsSpecialtyModule from '@/admin/store/settings/specialty/entity';
import RemoveModal from '@/components/modules/removeModal.vue';
import { formatNumberSpaced, formatRemoveSpaces } from '@/lib/utils/Utils';
import { numberRegExp } from '@/constants';

@Component({
    components: {
        RemoveModal,
        TitleReturn,
        BtnDefault,
        InputBlock,
        SelectCustom,
        BtnSubmit,
        CheckboxDefault,
    },
})
export default class SettingsSpecialtyCard extends Vue {
    returnTitleData = {
        icon: '',
        title: '',
    };

    get model() {
        return SettingsSpecialtyModule.model;
    }

    get cardId() {
        return SettingsSpecialtyModule.cardId;
    }

    getFormattedNumberString(value: string): string {
        return formatNumberSpaced(value);
    }

    handleName(value: string) {
        SettingsSpecialtyModule.updateName(value);
    }

    handleSort(value: string) {
        SettingsSpecialtyModule.updateTableSort(formatRemoveSpaces(value));
    }

    updateIsActive(params: { name: string; id: number; bool: boolean }) {
        SettingsSpecialtyModule.updateIsActive(params.bool);
    }

    returnBefore() {
        this.$router.push({ name: 'settings_specialties' });
    }

    send() {
        if (this.$route.params.specialtyId) {
            this.sendEdit();

            return;
        }
        this.sendAdd();
    }

    sendEdit() {
        SettingsSpecialtyModule.sendEdit();
    }

    sendAdd() {
        SettingsSpecialtyModule.sendAdd({ name: 'settings_specialties' });
    }

    initMask() {
        const settingsMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputsNumber = document.getElementsByClassName('j_number_mask_wrapper');

        for (const item of Object.values(inputsNumber)) {
            settingsMask.mask(item.getElementsByTagName('input')[0]);
        }
    }

    async mounted() {
        this.initMask();

        if (this.$route.params.specialtyId) {
            await SettingsSpecialtyModule.initCardById(this.$route.params.specialtyId);
            this.returnTitleData = SettingsSpecialtyModule.titleReturnData.edit;

            return;
        }

        this.returnTitleData = SettingsSpecialtyModule.titleReturnData.create;
    }

    beforeDestroy() {
        SettingsSpecialtyModule.reset();
    }
}
