import { CountryModelInterface } from '@/interfaces/models/country.interface';
import InputEntity from '@/lib/formFactory/inputEntity';

export default class EntityModel {
    model: CountryModelInterface;

    constructor() {
        const nameEntity = new InputEntity('name');
        nameEntity.setTitle('Название страны');
        nameEntity.initLocalization();

        const sortEntity = new InputEntity('sort');
        sortEntity.setTitle('Порядок сортировки');

        const phoneCodeEntity = new InputEntity('phoneCode');
        phoneCodeEntity.setTitle('Код');
        phoneCodeEntity.setSize(5);

        const phoneMaskEntity = new InputEntity('phoneMask');
        phoneMaskEntity.setTitle('Телефонная маска');
        phoneMaskEntity.setPlaceholder('999-999-99-99');

        const isoEntity = new InputEntity('iso');
        isoEntity.setTitle('ISO код страны');
        isoEntity.setPlaceholder('US');

        this.model = {
            name: nameEntity.entity[nameEntity.entityName],
            sort: sortEntity.entity[sortEntity.entityName],
            phoneCode: phoneCodeEntity.entity[phoneCodeEntity.entityName],
            phoneMask: phoneMaskEntity.entity[phoneMaskEntity.entityName],
            iso: isoEntity.entity[isoEntity.entityName],
        };
    }
}
