/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class ShopsFilter {
    filterHandlers = {
        update: 'SettingsSpecialtyFilter/updateFilter',
        reset: 'SettingsSpecialtyFilter/resetFilter',
        resetState: 'SettingsSpecialtyFilter/resetFilterState',
        removeSelected: 'SettingsSpecialtyFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        const filter = new FilterFactory();

        filter.createSuggestionsEntity('name');
        filter.setPlaceholder('name', 'Начните вводить название специализации');
        filter.setName('name', 'suggestions_name');
        filter.setPreviewName('name', 'Название');
        filter.setIconClassName('name', 'icon-user');
        filter.setAction('name', 'settingsSpecialty/updateSuggestionsSelectedFilter');
        filter.setSearchAction('name', 'settingsSpecialty/updateSuggestionsSearchFilter');

        this.filterModel = filter.filterModel;
    }
}
