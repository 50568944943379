
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SimpleRadioBtnComponent extends Vue {
    @Prop({ required: true }) readonly data!: { id: string; name: string; value: any };

    updateValue() {
        this.$emit('change', { id: this.data.id });
    }
}
