import API from '@/admin/config/api-routers';
import { makeGetRequest, makeFormRequest, makeDeleteRequest } from '@/api/helpers';

export const getSpecialtyAll = async () => {
    const result = await makeGetRequest(API.specialty.getAll);

    return result.data.result.table;
};

export const getSpecialtyList = async (pageCurrent: number, sort: string, filter: string, items: number | string) => {
    const result = await makeGetRequest(API.specialty.getList + `?page=${pageCurrent}&items=${items}&${sort}${filter}`);

    return result.data.result.table;
};

export const getSpecialtyCard = async (cardId: number | string) => {
    const result = await makeGetRequest(API.specialty.card + `${cardId}/`);

    return result.data.result.item;
};

export const editSpecialtyCard = async (cardId: number | string, data: any) => {
    const result = await makeFormRequest(API.specialty.card + `${cardId}/`, data);

    return result;
};

export const createSpecialtyCard = async (data: any) => {
    const result = await makeFormRequest(API.specialty.create, data);

    return result;
};

export const deleteSpecialtyCard = async (cardId: string) => {
    const result = await makeDeleteRequest(`${API.specialty.card}${cardId}/`);

    return result;
};
