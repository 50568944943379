import { Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import EntityBaseModule from '@/admin/store/entity';
import store from '@/admin/store';
import EntityModel from './entityModel';
import { SelectItem } from '@/lib/formFactory/select.interface';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import { convertBytes, formatCreatedAt, getApiErrorMessage } from '@/lib/utils/Utils';
import { importModelInterface } from '@/interfaces/models/import.interface';
import { createImportRequest, getImportCard, getParserFiles, getParsersTypes } from '@/admin/api/import';
import { isDateStartOverDateEnd } from '@/lib/utils/Utils';
import { processListForSelect } from '@/lib/utils/form';
import SelectEntity from '@/lib/formFactory/selectEntity';
import { ImportApiInterface } from '@/interfaces/import.interface';
import ImportModule from './index';
import moment from 'moment';

const MODULE_NAME = 'settingsImportEntity';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SettingsImportEntity extends EntityBaseModule {
    model: importModelInterface;

    constructor(module: SettingsImportEntity) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
    }

    @Mutation
    SET_TYPE(selected: SelectItem) {
        this.model.type.current = selected;
    }

    @Mutation
    SET_LOG(log: string) {
        this.model.log = log;
    }

    @Mutation
    SET_TYPE_LIST(list: SelectItem[]) {
        this.model.type.list = list;
    }

    @Mutation
    SET_FILE(selected: SelectItem) {
        this.model.file.current = selected;
    }

    @Mutation
    SET_FILE_LIST(list: SelectItem[]) {
        this.model.file.list = list;
    }

    @Mutation
    SET_FILE_DISABLED(bool: boolean) {
        this.model.file.disabled = bool;
    }

    @Mutation
    SET_DATE_FROM(date: string) {
        this.model.dateFrom.value = date;
    }

    @Mutation
    SET_DATE_TO(date: string) {
        this.model.dateTo.value = date;
    }

    @Mutation
    SET_AUCTION_ID(id: string) {
        this.model.auctionId.value = id;
    }

    @Mutation
    SET_PUBLISH_PLALCED(bool: boolean) {
        this.model.publishPlaced.list[0].checked = bool;
    }

    @Action({ rawError: true })
    async init(id?: number | null) {
        this.SET_IS_LOADING(true);

        if (id) {
            const data = await getImportCard(+id);
            this.setInfo(data);
        }

        if (!id) {
            const types = await getParsersTypes();
            this.SET_TYPE_LIST(await processListForSelect(types));
        }

        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async setInfo(data: ImportApiInterface) {
        this.SET_TYPE({ id: 0, value: data.type });
        this.SET_FILE({ id: 0, value: data.file });
        this.SET_AUCTION_ID(String(data.auctionId));
        this.SET_LOG(data.log);

        this.updateDateFrom(data.dateTimeStart ? await formatCreatedAt(data.dateTimeStart) : '');
        this.updateDateTo(data.dateTimeEnd ? await formatCreatedAt(data.dateTimeEnd) : '');
        this.SET_PUBLISH_PLALCED(data.publish);
    }

    @Action({ rawError: true })
    async updateType(selected: SelectItem) {
        this.SET_TYPE(selected);
        this.setValidateSelect({ index: 'type', field: this.model.type });

        this.SET_FILE(new SelectEntity('').defaultValue.current);

        if (!selected.id) {
            this.SET_FILE_DISABLED(true);

            return;
        }

        this.SET_FILE_DISABLED(false);

        const files = await getParserFiles(selected.id as string);
        const filesList = files.map((file: { name: string; size: number }) => {
            const sizeConverted = convertBytes(file.size);
            const size = sizeConverted.MB > 1 ? `${sizeConverted.MB} Мб` : `${sizeConverted.KB} Кб`;

            return {
                id: file.name,
                value: `${file.name} ${size}`,
            };
        });

        this.SET_FILE_LIST(filesList);
    }

    @Action({ rawError: true })
    updateFile(selected: SelectItem) {
        this.SET_FILE(selected);
    }

    @Action({ rawError: true })
    updateDateFrom(date: string) {
        this.SET_DATE_FROM(date);

        if (!this.model.dateTo.value) {
            return;
        }

        if (isDateStartOverDateEnd(date, this.model.dateTo.value, '/')) {
            this.SET_DATE_TO(date);
        }
    }

    @Action({ rawError: true })
    updateDateTo(date: string) {
        this.SET_DATE_TO(date);

        if (!this.model.dateFrom.value) {
            return;
        }

        if (isDateStartOverDateEnd(this.model.dateFrom.value, date, '/')) {
            this.SET_DATE_FROM(date);
        }
    }

    @Action({ rawError: true })
    updateAuctionId(id: string) {
        this.SET_AUCTION_ID(id);
    }

    @Action({ rawError: true })
    updatePublishPlaced(bool: boolean) {
        this.SET_PUBLISH_PLALCED(bool);
    }

    @Action({ rawError: true })
    async validate() {
        this.setValidateSelect({ index: 'type', field: this.model.type });
        this.setValidateSelect({ index: 'file', field: this.model.type });

        const fieldsWithError = await this.getFieldsWithError();

        return fieldsWithError?.length > 0 ? true : false;
    }

    @Action({ rawError: true })
    prepareData() {
        const data: {
            type: string | number;
            file: string;
            publish: number;
            dateTimeStart?: string;
            dateTimeEnd?: string;
            auctionId?: string;
        } = {
            type: this.model.type.current.id,
            file: this.model.file.current.id as string,
            publish: this.model.publishPlaced.list[0].checked ? 1 : 0,
        };

        if (this.model.dateFrom.value?.length) {
            data['dateTimeStart'] = moment(this.model.dateFrom.value, 'DD/MM/YY').utcOffset(0, true).format();
        }

        if (this.model.dateTo.value?.length) {
            data['dateTimeEnd'] = moment(this.model.dateTo.value, 'DD/MM/YY').utcOffset(0, true).format();
        }

        if (this.model.auctionId.value?.length) {
            data['auctionId'] = this.model.auctionId.value;
        }

        return data;
    }

    @Action({ rawError: true })
    async sendCreate() {
        const isError = await this.validate();

        if (isError) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        try {
            const data = await this.prepareData();
            const result = await createImportRequest(data);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Задача на импорт создана', type: 'ok' });
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'ok' });
            }

            this.reset();
            ImportModule.getTable();
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    reset() {
        const defaultSelectValue = new SelectEntity('').defaultValue.current;
        this.SET_TYPE(defaultSelectValue);
        this.SET_TYPE_LIST([]);
        this.SET_FILE(defaultSelectValue);
        this.SET_FILE_LIST([]);
        this.SET_DATE_FROM('');
        this.SET_DATE_TO('');
        this.SET_AUCTION_ID('');
        this.SET_PUBLISH_PLALCED(false);

        this.resetErrorState();
    }

    @Action({ rawError: true })
    resetErrorState() {
        this.RESET_ERROR_BY_FIELD('type');
        this.RESET_ERROR_BY_FIELD('dateFrom');
        this.RESET_ERROR_BY_FIELD('dateTo');
        this.RESET_ERROR_BY_FIELD('auctionId');
    }
}
export default getModule(SettingsImportEntity);
