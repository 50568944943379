/**
 * Content common store.
 *
 */

import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/admin/store/page';
import store from '@/admin/store';
import { tabsNav } from './tabs';

import PageEntity from '@/lib/layouts/page/pageEntity';

export const MODULE_NAME = 'settings';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SettingsModule extends PageBaseModule {
    allTabs = tabsNav;
    tabsNav = {};

    constructor(module: SettingsModule) {
        super(module);

        const page = new PageEntity();
        page.setTitle('');
        this.pageSettings = page.values;
    }

    @Mutation
    SET_TABS_NAV(list: { [index: string]: {} }) {
        this.tabsNav = list;
    }

    @Action({ rawError: true })
    initTabsItems() {
        this.SET_TABS_NAV(tabsNav);
    }
}

export default getModule(SettingsModule);
