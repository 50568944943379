
import { InputSuggestionMultipleItem } from '@/lib/formFactory/inputSuggestionMultiple.interface';
import { Vue, Component, Prop } from 'vue-property-decorator';

import TreeNode from './TreeNode.vue';

const getSelectedCategories = (categories: InputSuggestionMultipleItem[]): string[] => {
    return categories.reduce((accum: string[], current) => {
        if (current.selected) {
            accum.push(current.value);
        }

        if (current.children?.length) {
            accum = [...accum, ...getSelectedCategories(current.children)];
        }

        return accum;
    }, []);
};

@Component({
    components: {
        TreeNode,
    },
})
export default class SuggestionsTree extends Vue {
    @Prop() data!: any;
    @Prop() isSelect!: boolean;
    @Prop() isSelectMultiple!: boolean;

    isShowHints = false;

    get listKey(): string {
        return this.isSelect ? 'list' : 'hintsList';
    }

    get joinedSelectedItems(): string {
        return getSelectedCategories(this.data[this.listKey]).join(', ');
    }

    showHints(e?: Event): void {
        if (this.data.disabled) {
            return;
        }

        this.$emit('input', (e?.target as HTMLInputElement)?.value ?? '');

        this.isShowHints = true;
    }

    close(): void {
        this.isShowHints = false;
    }

    handleClick(data: InputSuggestionMultipleItem): void {
        const value = data;

        value.selected = !value.selected;
        
        this.$emit('click', value);

        if (this.isSelect) {
            this.close();
        }
    }

    updateSearch(e: Event): void {
        const value = (e.target as HTMLInputElement).value;
        this.showHints();
        this.$emit('input', value);
    }

    documentClick(e: Event): void {
        const el = this.$refs.dropdownSearch as HTMLElement;
        const target = e.target as Node;

        if (this.isShowHints === true) {
            if (el !== target && !el.contains(target)) {
                this.close();
                this.$emit('focusout', this.data.value);
            }
        }
    }

    created(): void {
        document.body.addEventListener('click', this.documentClick);
    }
    updated(): void {
        document.body.addEventListener('click', this.documentClick);
    }
    destroyed(): void {
        document.body.removeEventListener('click', this.documentClick);
    }
}
