
import { Component, Vue } from 'vue-property-decorator';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import Loading from '@/components/Loading.vue';
import App from './App.vue';

import SettingsModule from '@/admin/store/settings';
import SettingsActionModule from '@/admin/store/settings/auctions';
import SettingsPainterModule from '@/admin/store/settings/auctions/painter';
import SettingsGalleryModule from '@/admin/store/settings/auctions/gallery';
import { getCategoryList } from '@/admin/api/category';

@Component({
    components: {
        TabsNav,
        TitleReturn,
        Loading,
        App,
    },
})
export default class SettingsAuctionMain extends Vue {
    init = false;
    categories = [];

    get settings() {
        return SettingsActionModule.pageSettings;
    }

    get tabsNav() {
        return SettingsModule.tabsNav;
    }

    get currentTab() {
        return SettingsModule.allTabs.auctions.id;
    }

    async mounted() {
        await SettingsModule.initTabsItems();
        await Promise.all([
            await SettingsActionModule.init(),
            await SettingsPainterModule.init(),
            await SettingsGalleryModule.init(),
        ]);

        let { rows: categories } = await getCategoryList(1, 'sort[0][id]=name&sort[0][value]=ASC', '&items=1000', 0);

        this.categories = categories.map((item: any) => {
            return {
                category: {
                    id: item.id,
                    name: item.name,
                },
            };
        });

        this.init = true;
    }
}
