var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mr-table-main-block__wrapper"},[_c('div',{ref:"scrollbar_bottom",staticClass:"mr-table-block__wrapper"},[(_vm.pagination && Object.values(_vm.pagination).length && _vm.actionPagination)?_c('pagination-block',{staticClass:"top",attrs:{"data":_vm.pagination,"action":_vm.actionPagination},on:{"select-amount":_vm.selectAmount}}):_vm._e(),_c('div',{staticClass:"mr-table-block__table"},[_c('table',{ref:"table",staticClass:"mr-table__wrapper"},[_c('table-title',{attrs:{"titles":_vm.titles,"hidden-columns":_vm.hidden_columns,"sort-fields":_vm.sort_fields,"is-status-column":false,"align-right-column":_vm.alignRightColumn},on:{"toggle-sort":_vm.toggleSort,"add":_vm.add}}),_c('tbody',{staticClass:"cp-table-tbody__mask"}),(_vm.isTotal && _vm.rows && Object.values(_vm.rows).length > 0)?_c('tbody',{staticClass:"cp-table-tbody__mask-total"}):_vm._e(),_c('tbody',_vm._l((_vm.rows),function(row,index){return _c('tr',{key:row.id,class:{ 'not-active': row.is_active_row === false, bold: row.is_bold }},_vm._l((row),function(value,id){return _c('td',{key:id,class:{
                                'actions-wrapper': id === '_actions',
                                hidden: _vm.hidden_columns.includes(id),
                                small: id === 'id',
                            },attrs:{"colspan":_vm.colspan && id === _vm.colspan.id ? _vm.colspan.span : 1}},[((id === 'name' || id === 'title' || id === 'fullName') && _vm.routeName)?_vm._t("default",function(){return [_c('router-link',{staticClass:"cp-table__item-link",attrs:{"to":{ name: _vm.routeName, params: { [_vm.routeParam]: row.id } }},domProps:{"innerHTML":_vm._s(value)}})]}):(id === 'input_to' && index === _vm.rows.length - 1)?_c('div',{staticClass:"cp-margin__l--form"},[_c('infinity-icon')],1):(id.indexOf('input_') === 0)?_c('div',{staticClass:"cp-flex align-center"},[(row[`is_${id}`] != null)?_c('simple-radio-btn',{attrs:{"data":{ id: `${row.id}_${id}`, name: row.id, value: row[`is_${id}`] }},on:{"change":function($event){return _vm.actionHandler('radio', `is_${id}`, $event)}}}):_vm._e(),_c('input',{staticClass:"cp-input-radius-50 j_number_mask_wrapper",class:{ disabled: !row[`is_${id}`] },attrs:{"type":"text","disabled":row[`is_${id}`] != null && !row[`is_${id}`]},domProps:{"value":value},on:{"input":function($event){return _vm.inputHandler(id, row.id, $event)},"change":function($event){return _vm.changeHandler(id, row.id, $event)},"focusout":function($event){return _vm.focusoutHandler(id, row.id, $event)}}})],1):(
                                    _vm.actionsHandler.additional && Object.keys(_vm.actionsHandler.additional).includes(id)
                                )?_vm._t("default",function(){return [_c('span',{class:{ 'cp-cursor-pointer': value },on:{"click":function($event){return _vm.actionHandler(id, row.id)}}},[(value)?_vm._t("default",function(){return [_c('span',{domProps:{"innerHTML":_vm._s(value)}})]}):_vm._t("default",function(){return [_vm._v(" - ")]})],2)]}):(id === '_actions')?_vm._t("default",function(){return _vm._l((value),function(name){return _c('span',{key:name,staticClass:"mr-admin-actions-icons",class:name,on:{"click":function($event){return _vm.actionHandler(name, row.id)}}},[(name === 'delete')?_c('icon-font',{attrs:{"size":"11","icon":"close"},nativeOn:{"click":function($event){return _vm.$emit('delete-item', row.id)}}}):_vm._e()],1)})}):(_vm.createdAtNames.includes(id))?_vm._t("default",function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.parseDate(value))+" ")])]}):(typeof value === 'object' && 'value' in value)?_c('div',{staticClass:"cp-flex align-center"},[_c('input',{staticClass:"cp-input-radius-50 j_number_mask_wrapper",attrs:{"type":"text"},domProps:{"value":value.value},on:{"input":function($event){return _vm.inputHandler(id, row.id, $event)},"change":function($event){return _vm.changeHandler(id, row.id, $event)},"focusout":function($event){return _vm.focusoutHandler(id, row.id, $event)}}})]):_vm._t("default",function(){return [(value !== null)?_vm._t("default",function(){return [_c('span',{domProps:{"innerHTML":_vm._s(value)}})]}):_vm._t("default",function(){return [_vm._v(" - ")]})]})],2)}),0)}),0)],1)]),(_vm.actionPagination && Object.values(_vm.pagination).length && _vm.pagination)?_c('pagination-block',{attrs:{"data":_vm.pagination,"action":_vm.actionPagination},on:{"select-amount":_vm.selectAmount}}):_vm._e(),(_vm.isBottomTable && _vm.rows && Object.values(_vm.rows).length > 0)?_c('div',{staticClass:"cp-table-bottom__wrapper"}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }