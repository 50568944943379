/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class ShopsFilter {
    filterHandlers = {
        update: 'SettingsCityFilter/updateFilter',
        reset: 'SettingsCityFilter/resetFilter',
        resetState: 'SettingsCityFilter/resetFilterState',
        removeSelected: 'SettingsCityFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        const filter = new FilterFactory();

        filter.createSuggestionsEntity('country');
        filter.setPlaceholder('country', 'Начните вводить название страны');
        filter.setName('country', 'suggestions_country');
        filter.setPreviewName('country', 'Страна');
        filter.setAction('country', 'settingsCity/updateSuggestionsSelected');
        filter.setSearchAction('country', 'settingsCity/updateCountrySuggestionsValue');
        filter.setIconClassName('country', 'icon-compass');

        filter.createSuggestionsEntity('name');
        filter.setPlaceholder('name', 'Начните вводить название города');
        filter.setName('name', 'suggestions_city');
        filter.setPreviewName('name', 'Город');
        filter.setAction('name', 'settingsCity/updateSuggestionsSelected');
        filter.setSearchAction('name', 'settingsCity/updateCitySuggestionsValue');
        filter.setIconClassName('name', 'icon-compass');

        this.filterModel = filter.filterModel;
    }
}
