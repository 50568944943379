import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '../../page';
import PageEntity from '@/lib/layouts/page/pageEntity';
import store from '@/admin/store';
import { getCitiesList } from '@/admin/api/city';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';

const MODULE_NAME = 'countryCities';

@Module({ dynamic: true, name: MODULE_NAME, store, namespaced: true })
class CountryCitiesModule extends PageBaseModule {
    countryId = '';

    constructor(module: CountryCitiesModule) {
        super(module);

        const page = new PageEntity();

        page.setTitle('Города');
        page.setSortName('settingsCountryCitySort');
        page.setDefaultSortField('name');
        this.pageSettings = page.values;
    }

    @Mutation
    SET_COUNTRY_ID(id: string) {
        this.countryId = id;
    }

    @Action({ rawError: true })
    async init(countryId: string) {
        this.SET_COUNTRY_ID(countryId);
        this.setDefaultSort();
        this.getList();
    }

    @Action({ rawError: true })
    async getList() {
        this.SET_IS_LOADING(true);
        const sort = await this.getSortForRequest();
        const result = await getCitiesList(
            this.pageSettings.pageCurrent,
            sort,
            `filters[0][id]=country&filters[0][value]=${this.countryId}`,
            +this.pageSettings.pageAmountItems,
        );

        this.SET_TABLE(await this.prepareTable(result));
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    prepareTable(table: TableApiInterface) {
        const hiddenColumns = Object.values(table.titles)
            .filter((title) => title.id !== 'name')
            .map((title) => title.id);

        const rows: any = [];
        let index: string;
        let row: any;
        for ([index, row] of Object.entries(table.rows)) {
            const rowProcessed: any = {};
            for (const i of Object.keys(row)) {
                let cell: any = row[i];

                if (i === 'name') {
                    cell = `<span class="cp-table-column__accent">${cell}</span>`;
                }

                rowProcessed[i] = cell;
            }

            rows[index] = rowProcessed;
        }

        return {
            titles: table.titles,
            rows: rows,
            sortFields: table.sortFields,
            pagination: table.pagination ? table.pagination : {},
            totalItems: table.totalItems,
            filters: table.filters,
            hiddenColumns: hiddenColumns,
        } as TableInterface;
    }
}
export default getModule(CountryCitiesModule);
