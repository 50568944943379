import { Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import PageBaseModule from '@/admin/store/page';
import store from '@/admin/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { getSettingAuction } from '@/admin/api/settingAuction';
export const MODULE_NAME = 'settingPainter';

import { SettingsPainterInterface } from '@/interfaces/settingsPainter';
import {
    CategoryPainter,
    PainterProductivityCategoryInterface,
    PainterProductivityInterface,
    PainterProductivityItemInterface,
} from '@/interfaces/settingsAuctions';
import { SelectItem } from '@/lib/formFactory/select.interface';
import { productivityValueReducer } from '@/lib/utils/painterProductivity';

type ProductivityTableEntry = [string, PainterProductivityItemInterface];

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SettingsAuction extends PageBaseModule {
    settings: SettingsPainterInterface[] = [];
    saveCounter = 0;
    productivity: PainterProductivityInterface = {};
    productivityIndex = 0;
    defaultCategoryRow: PainterProductivityCategoryInterface = {
        id: 0,
        category: { id: 0, name: '' },
        startPrice: 0,
        maxPrice: 0,
        priceBuyNow: 0,
        fix: {
            checked: true,
            value: 0,
        },
        percent: {
            checked: false,
            value: 0,
        },
        _actions: [],
    };

    constructor(module: SettingsAuction) {
        super(module);
        const page = new PageEntity();

        page.setTitle('Настройка аукционов');
        this.pageSettings = page.values;
    }

    @Mutation
    SET_SAVE_COUNTER() {
        this.saveCounter++;
    }

    @Mutation
    SET_SETTINGS(value: SettingsPainterInterface[]) {
        this.settings = value;
    }

    @Mutation
    SET_PRODUCTIVITY(productivity: any) {
        this.productivity = productivity;
    }

    @Mutation
    SET_PRODUCTIVITY_LIMIT(params: { limit: string; id: number }) {
        this.productivity[params.id].limit = +params.limit;
    }

    @Mutation
    SET_RE_EXPOSE_TIME(params: { time: string; id: number }) {
        this.productivity[params.id].reExposeTime = +params.time;
    }

    @Mutation
    SET_CATEGORIES(params: { categories: any; id: number }) {
        this.productivity[params.id].categories = params.categories;
    }

    @Mutation
    SET_ITEM_PAINTER(payload: { name: string; value: any }) {
        this.settings.forEach((item: any) => {
            if (payload.name === item.name) {
                item.value = payload.value;
            }
        });
    }

    @Mutation
    SET_PRODUCTIVITY_INDEX(index: number) {
        this.productivityIndex = index;
    }

    @Action({ rawError: true })
    async init() {
        try {
            const result = await getSettingAuction('?section=CommonPainter');

            this.setSettings(result);
        } catch (err) {
            return err;
        }
    }

    @Action({ rawError: true })
    updateProductivityIndex(index: number) {
        this.SET_PRODUCTIVITY_INDEX(index);
    }

    @Action({ rawError: true })
    setSettings(settings: SettingsPainterInterface[]) {
        if (settings == null) {
            settings = [];
        }

        const productivitySetting = settings.filter(
            (setting: SettingsPainterInterface) => setting.name === 'productivity',
        )[0];

        if (productivitySetting?.value == null) {
            this.addProductivityItem();

            return;
        }

        const productivityValue = JSON.parse(productivitySetting.value as string);
        let productivityEntries = [];

        if (!productivityValue || !productivityValue.length) {
            productivityEntries.push(['0'], {
                range: {
                    from: 0,
                    to: 0,
                },
                limit: 0,
                reExposeTime: 0,
                categories: [],
            });
        }

        if (productivityValue.length) {
            productivityEntries = productivityValue.reduce(productivityValueReducer, []);
        }

        const productivity: PainterProductivityInterface = Object.fromEntries(productivityEntries);

        const settingsProcessed = settings.reduce((result: SettingsPainterInterface[], setting: any) => {
            const index = setting.name.indexOf('.') !== -1 ? setting.name.split('.')[1] : null;

            if (setting.name.indexOf('limit') !== -1 && productivity[index]) {
                productivity[index] = productivity[index] ?? {};
                productivity[index].limit = +setting.value;

                return result;
            }

            if (setting.name.indexOf('reExposeTime') !== -1 && productivity[index]) {
                productivity[index].reExposeTime = +setting.value;

                return result;
            }

            if (setting.name.indexOf('categories') !== -1 && productivity[index]) {
                const categories = JSON.parse(setting.value)?.map((category: any, index: number) => {
                    return { ...category, id: index, _actions: ['delete'] };
                });

                productivity[index].categories = categories.length
                    ? categories
                    : [
                          {
                              ...this.defaultCategoryRow,
                              id: 0,
                              _actions: [],
                          },
                      ];

                return result;
            }

            if (setting.name === 'productivity') {
                return result;
            }

            return [...result, setting];
        }, []);

        this.SET_SETTINGS(settingsProcessed);
        this.SET_PRODUCTIVITY(productivity);
    }

    @Action({ rawError: true })
    deleteProductivityByIndex(index: number) {
        let prevEntry: PainterProductivityItemInterface;
        const productivityEntries = Object.entries(this.productivity).reduce(
            (
                entries: [string, PainterProductivityItemInterface][],
                entry: [string, PainterProductivityItemInterface],
                _,
                array,
            ) => {
                if (+entry[0] === index - 1 && index === array.length - 1) {
                    (entry[1] as any).range.to = '';
                }

                if (+entry[0] === index) {
                    return entries;
                }

                if (prevEntry) {
                    entry[1].range.from = +prevEntry.range.to;
                }

                if (entries.length === 0) {
                    entry[1].range.from = 0;
                }

                entry[0] = String(entries.length);
                prevEntry = entry[1];

                return [...entries, entry];
            },
            [],
        );

        const newProductivity = Object.fromEntries(productivityEntries);

        this.SET_PRODUCTIVITY(newProductivity);

        if (!productivityEntries.length) {
            this.addProductivityItem();
        }
    }

    @Action({ rawError: true })
    updateProductivityLimit(params: { limit: string; id: number }) {
        this.SET_PRODUCTIVITY_LIMIT(params);
    }

    @Action({ rawError: true })
    updateProductivityReExposeTime(params: { time: string; id: number }) {
        this.SET_RE_EXPOSE_TIME(params);
    }

    @Action({ rawError: true })
    updateProductivityCategories(params: { categories: any[]; id: number }) {
        this.SET_CATEGORIES(params);
    }

    @Action({ rawError: true })
    addEmptyRow() {
        for (const key of Object.keys(this.productivity)) {
            const keyAsNum = +key;

            const rows = this.productivity[keyAsNum].categories.map((row: CategoryPainter) => {
                return { ...row, _actions: ['delete'] };
            });

            rows.push({
                ...this.defaultCategoryRow,
                id: this.productivity[keyAsNum].categories.length,
                _actions: this.productivity[keyAsNum].categories.length ? ['delete'] : [],
                startPrice: 10000,
                maxPrice: 10000,
                priceBuyNow: 10000,
                fix: {
                    checked: true,
                    value: 0,
                },
                percent: {
                    checked: false,
                    value: 0,
                },
            });

            this.SET_CATEGORIES({ categories: rows, id: keyAsNum });
        }
    }

    @Action({ rawError: true })
    addProductivityItem() {
        const productivityLength = Object.values(this.productivity).length;
        const prevProductivityItem = this.productivity[productivityLength - 1];
        const newProductivity = {
            range: {
                from: prevProductivityItem ? +this.productivity[productivityLength - 1].range.to + 1 : 0,
                to: 0,
            },
            limit: 0,
            reExposeTime: 0,
            categories: [this.defaultCategoryRow],
        };

        this.SET_PRODUCTIVITY({ ...this.productivity, [productivityLength]: newProductivity });
    }

    @Action({ rawError: true })
    async updateProductivityRangeTo(params: { value: number; id: number }) {
        const productivity = { ...this.productivity };

        const productivityEntries = Object.entries(productivity).map((entry) => {
            const entryValue = entry[1] as PainterProductivityItemInterface;
            if (+entry[0] === params.id) {
                const range = entryValue.range;
                const value = params.value || '';
                range.to = !value || value > range.from ? value : range.from + 1;
            }

            return [entry[0], entryValue];
        });

        const newProductivity = await this.getValidateTable(Object.fromEntries(productivityEntries));

        this.SET_PRODUCTIVITY(newProductivity);
    }

    @Action({ rawError: true })
    getValidateTable(table: PainterProductivityInterface): PainterProductivityInterface {
        let previousEntry: ProductivityTableEntry;
        let isReturn = false;
        const productivityEntries = Object.entries(table).reduce(
            (result: ProductivityTableEntry[], entry, index, arr) => {
                const entryValue = entry[1];

                if (isReturn) {
                    return result;
                }

                if (!entryValue.range.to && index !== arr.length) {
                    isReturn = true;
                }

                if (previousEntry) {
                    const previousEntryRangeTo = +previousEntry[1].range.to;
                    entryValue.range.from = previousEntryRangeTo + 1;
                    entryValue.range.to =
                        !entryValue.range.to || entryValue.range.to > entryValue.range.from
                            ? entryValue.range.to
                            : entryValue.range.from + 1;
                }

                const resultEntry: ProductivityTableEntry = [String(index), entryValue];
                previousEntry = resultEntry;
                result.push(resultEntry);

                return result;
            },
            [],
        );

        if (productivityEntries[productivityEntries.length - 1][1].range.to) {
            const newRow = {
                range: {
                    from: +productivityEntries[productivityEntries.length - 1][1].range.to + 1,
                    to: '',
                },
                limit: 0,
                reExposeTime: 0,
                categories: [...productivityEntries[productivityEntries.length - 1][1].categories],
            };

            productivityEntries.push([String(productivityEntries.length), newRow]);
        }

        return Object.fromEntries(productivityEntries);
    }

    @Action({ rawError: true })
    selectCategory(params: { id: number; rowId: number; selected: SelectItem }) {
        const productivity = { ...this.productivity };

        const productivityEntries = Object.entries(productivity).map((entry) => {
            const entryValue = entry[1] as PainterProductivityItemInterface;
            entryValue.categories = entryValue.categories.map((category) => {
                if (category.id !== params.rowId) {
                    return category;
                }

                return { ...category, category: { id: +params.selected.id, name: params.selected.value } };
            });

            return [entry[0], entryValue];
        });

        this.SET_PRODUCTIVITY(Object.fromEntries(productivityEntries));
    }

    @Action({ rawError: true })
    deleteCategoryById(rowId: number) {
        const productivity = { ...this.productivity };

        const productivityEntries = Object.entries(productivity).map((entry) => {
            const entryValue = entry[1] as PainterProductivityItemInterface;
            entryValue.categories = entryValue.categories.reduce(
                (
                    result: PainterProductivityCategoryInterface[],
                    category: PainterProductivityCategoryInterface,
                    index,
                ) => {
                    if (category.id !== rowId) {
                        return [...result, { ...category, id: index }];
                    }

                    if (entryValue.categories.length - 1 === index && !result.length) {
                        return [...result, this.defaultCategoryRow];
                    }

                    return result;
                },
                [],
            );

            return [entry[0], entryValue];
        });

        this.SET_PRODUCTIVITY(Object.fromEntries(productivityEntries));
    }

    @Action({ rawError: true })
    updateCategoryValue(params: { rowId: number; fieldName: string; value: string }) {
        const productivity = { ...this.productivity };

        const productivityEntries = Object.entries(productivity).map((entry) => {
            const entryValue = entry[1] as PainterProductivityItemInterface;
            if (+entry[0] === this.productivityIndex) {
                entryValue.categories = entryValue.categories.map((category, index) => {
                    if (params.rowId === index && category[params.fieldName].value != null) {
                        category[params.fieldName].value = params.value;
                    }

                    if (params.rowId === index && category[params.fieldName].value == null) {
                        category[params.fieldName] = params.value;
                    }

                    return category;
                });
            }

            return [entry[0], entryValue];
        });

        this.SET_PRODUCTIVITY(Object.fromEntries(productivityEntries));
    }

    @Action({ rawError: true })
    updateCategoryCheckboxValue(params: { fieldName: string; rowId: number; bool: boolean }) {
        const productivity = { ...this.productivity };

        const productivityEntries = Object.entries(productivity).map((entry) => {
            const entryValue = entry[1] as PainterProductivityItemInterface;
            if (+entry[0] === this.productivityIndex) {
                entryValue.categories = entryValue.categories.map((category, index) => {
                    if (params.rowId === index) {
                        category['percent'].checked = params.fieldName === 'percent' ? params.bool : !params.bool;
                        category['fix'].checked = params.fieldName === 'fix' ? params.bool : !params.bool;
                    }

                    return category;
                });
            }

            return [entry[0], entryValue];
        });

        this.SET_PRODUCTIVITY(Object.fromEntries(productivityEntries));
    }

    @Action({ rawError: true })
    async setItem(value: any) {
        this.SET_ITEM_PAINTER(value);
    }

    @Action({ rawError: true })
    async setSaveCounter() {
        this.SET_SAVE_COUNTER();
    }
}

export default getModule(SettingsAuction);
