/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class ShopsFilter {
    filterHandlers = {
        update: 'SettingsCategoryFilter/updateFilter',
        reset: 'SettingsCategoryFilter/resetFilter',
        resetState: 'SettingsCategoryFilter/resetFilterState',
        removeSelected: 'SettingsCategoryFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        const filter = new FilterFactory();

        filter.createSuggestionsEntity('name');
        filter.setPlaceholder('name', 'По названию');
        filter.setName('name', 'suggestions_name');
        filter.setPreviewName('name', 'Название');
        filter.setAction('name', 'settingsCategory/updateSuggestionsSelectedFilter');
        filter.setSearchAction('name', 'settingsCategory/updateSuggestionsSearchFilter');
        filter.setIconClassName('name', 'icon-book');

        this.filterModel = filter.filterModel;
    }
}
