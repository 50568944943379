export const tabsNav = {
    auctions: {
        id: 'auctions',
        name: 'Настройки аукционов',
        template: 'auctions',
        pathName: 'settings_auctions',
        icon: 'settings',
        iconSize: '16',
    },
    cities: {
        id: 'cities',
        name: 'Города',
        template: 'cities',
        pathName: 'settings_cities',
        icon: 'city',
        iconSize: '16',
    },
    countries: {
        id: 'countries',
        name: 'Страны',
        template: 'countries',
        pathName: 'settings_countries',
        icon: 'globe',
        iconSize: '16',
    },
    specialties: {
        id: 'specialties',
        name: 'Специализации авторов',
        template: 'settings_specialties',
        pathName: 'settings_specialties',
        icon: 'user',
        iconSize: '16',
    },
    categories: {
        id: 'categories',
        name: 'Категории сайта',
        template: 'categories',
        pathName: 'settings_categories',
        icon: 'book',
        iconSize: '16',
    },
    color: {
        id: 'color',
        name: 'Цвета',
        template: 'colors',
        pathName: 'settings_colors',
        icon: 'split',
        iconSize: '16',
    },
    currency: {
        id: 'currency',
        name: 'Валюты',
        template: 'currency',
        pathName: 'settings_currencies',
        icon: 'currency',
        iconSize: '16',
    },
    import: {
        id: 'import',
        name: 'Импорт',
        template: 'import',
        pathName: 'settings_import',
        icon: 'settings',
        iconSize: '16',
    },
};
