
import { Component, Vue } from 'vue-property-decorator';
import FlexLayout from '@/admin/layouts/FlexLayout.vue';
import Loading from '@/components/Loading.vue';
import TableBlock from '@/components/table/Table.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';

// import FilterModule from '@/admin/store/filter';
import SettingsModule from '@/admin/store/settings';
import SettingsSpecialtyModule from '@/admin/store/settings/specialty';

@Component({
    components: {
        FlexLayout,
        Loading,
        TableBlock,
        AppHeaderMain,
        TabsNav,
        TitleReturn,
    },
})
export default class SettingsSpecialtyMain extends Vue {
    get settings() {
        return SettingsSpecialtyModule.pageSettings;
    }

    get filterSettings() {
        return SettingsSpecialtyModule.filter.filterSettings;
    }

    get isLoading(): boolean {
        return SettingsSpecialtyModule.isLoading;
    }

    get tabsNav() {
        return SettingsModule.tabsNav;
    }

    get currentTab() {
        return SettingsModule.allTabs.specialties.id;
    }

    get paginationAction() {
        return SettingsSpecialtyModule.updatePage;
    }

    get paginationItems(): { action: (value: string | number) => void; items: string } {
        return {
            action: SettingsSpecialtyModule.updateItems,
            items: SettingsSpecialtyModule.pageSettings.pageAmountItems,
        };
    }

    tableAction(params: any) {
        if (params.action === 'sort') {
            SettingsSpecialtyModule.sort({ field: params.field, sort: params.sort });
        }
    }

    updateList() {
        SettingsSpecialtyModule.getList();
    }

    async resetFilters() {
        await SettingsSpecialtyModule.clearSort();
        await SettingsSpecialtyModule.filter.resetFilter();
        SettingsSpecialtyModule.getList();
    }

    toAdd() {
        this.$router.push({ name: 'settings_specialty_create' });
    }

    mounted(): void {
        SettingsSpecialtyModule.init();
        SettingsModule.initTabsItems();
    }

    beforeDestroy(): void {
        SettingsSpecialtyModule.reset();
    }
}
