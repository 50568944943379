import { importModelInterface } from '@/interfaces/models/import.interface';
import CheckboxEntity from '@/lib/formFactory/checkboxEntity';
import InputDatePickerEntity from '@/lib/formFactory/inputDatePickerEntity';
import InputEntity from '@/lib/formFactory/inputEntity';
import SelectEntity from '@/lib/formFactory/selectEntity';

export default class EntityModel {
    model: importModelInterface;

    constructor() {
        const typeEntity = new SelectEntity('type');
        typeEntity.setTitle('Источник');
        typeEntity.setRequired(true);

        const fileEntity = new SelectEntity('file');
        fileEntity.setTitle('Файл');
        typeEntity.setRequired(true);

        const dateFromEntity = new InputDatePickerEntity('dateFrom');

        const dateToEntity = new InputDatePickerEntity('dateTo');

        const auctionIdEntity = new InputEntity('auctionId');
        auctionIdEntity.setTitle('ID Аукциона');

        const publishPlacedEntity = new CheckboxEntity('publishPlaced');
        publishPlacedEntity.setList({
            0: {
                id: 1,
                name: 'Опубликовать сразу загруженные аукционы',
                checked: false,
            },
        });

        this.model = {
            type: typeEntity.entity[typeEntity.entityName],
            file: fileEntity.entity[fileEntity.entityName],
            dateFrom: dateFromEntity.entity[dateFromEntity.entityName],
            dateTo: dateToEntity.entity[dateToEntity.entityName],
            auctionId: auctionIdEntity.entity[auctionIdEntity.entityName],
            publishPlaced: publishPlacedEntity.entity[publishPlacedEntity.entityName],
            log: '',
        };
    }
}
