/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class ShopsFilter {
    filterHandlers = {
        update: 'SettingsCountryFilter/updateFilter',
        reset: 'SettingsCountryFilter/resetFilter',
        resetState: 'SettingsCountryFilter/resetFilterState',
        removeSelected: 'SettingsCountryFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        const filter = new FilterFactory();

        filter.createSuggestionsEntity('name');
        filter.setPlaceholder('name', 'Начните вводить название страны');
        filter.setName('name', 'suggestions_name');
        filter.setPreviewName('name', 'Страна');
        filter.setAction('name', 'settingsCountry/updateSuggestionsSelected');
        filter.setSearchAction('name', 'settingsCountry/updateSuggestionsValue');
        filter.setIconClassName('name', 'icon-compass');

        this.filterModel = filter.filterModel;
    }
}
