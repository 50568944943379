
import { Component } from 'vue-property-decorator';
import TableBlock from '@/components/table/Table.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';

import BtnDefault from '@/components/BtnDefault.vue';
import InputBlock from '@/components/form/InputMain.vue';
import InputLocalization from '@/components/input/InputLocalization.vue';
import LocalizationLayout from '@/admin/layouts/LocalizationLayout.vue';
import SelectCustom from '@/components/select/SelectCustom.vue';
import CheckboxCustom from '@/components/form/CheckboxDefault.vue';
import ImageBlock from '@/components/imageInput/ImageInput.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import TextareaCustom from '@/components/form/Textarea.vue';
import Loading from '@/components/Loading.vue';
import InputSuggestions from '@/components/input/InputSuggestions.vue';

import Inputmask from 'inputmask';
import SettingsCategoryModule from '@/admin/store/settings/category/entity';
import RemoveModal from '@/components/modules/removeModal.vue';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { numberRegExp, slugRegExp } from '@/constants';
import { formatNumberSpaced, formatRemoveSpaces, imageToFile } from '@/lib/utils/Utils';
import { LocalizationParams } from '@/interfaces/localization';

@Component({
    components: {
        RemoveModal,
        TableBlock,
        AppHeaderMain,
        TabsNav,
        TitleReturn,
        BtnDefault,
        InputBlock,
        InputLocalization,
        SelectCustom,
        CheckboxCustom,
        BtnSubmit,
        ImageBlock,
        TextareaCustom,
        Loading,
        InputSuggestions,
    },
})
export default class SettingsCategoryCard extends LocalizationLayout {
    returnTitleData = {
        icon: '',
        title: '',
    };
    returnTitleIcon = 'compass';

    get model() {
        return SettingsCategoryModule.model;
    }

    get categoryName() {
        return SettingsCategoryModule.categoryName;
    }

    get isLoading() {
        return SettingsCategoryModule.isLoading;
    }

    getFormattedNumberString(value: string | number): string {
        return formatNumberSpaced(value);
    }

    updateName(params: LocalizationParams): void {
        SettingsCategoryModule.updateLocalizationField({ ...params, field: 'name' });
    }

    updateDescription(params: LocalizationParams): void {
        SettingsCategoryModule.updateLocalizationField({ ...params, field: 'description' });
    }

    async handleImage(file: File) {
        const toFile = await imageToFile(file);

        SettingsCategoryModule.updatePhotoFile(toFile);
    }

    removePreview() {
        SettingsCategoryModule.removeImagePreview();
    }

    updateSearchParentCategory(search: string) {
        SettingsCategoryModule.searchParentCategory(search);
    }

    updateSelectedParentCategory(selected: InputSuggestionItem) {
        SettingsCategoryModule.updateSuggestionsSelectedByType({ selected, type: 'parentCategory' });
    }

    updateParentCategory(category: { id: number; value: string }) {
        SettingsCategoryModule.updateParentCategory(category);
    }

    updateIsActive(params: { name: string; id: number; bool: boolean }) {
        SettingsCategoryModule.updateIsActive(params.bool);
    }

    updateSortOrder(value: string) {
        SettingsCategoryModule.updateSortOrder(formatRemoveSpaces(value));
    }

    updateSlug(value: string): void {
        SettingsCategoryModule.updateModelFieldValue({ field: 'slug', value });
    }

    returnBefore() {
        this.$router.push({ name: 'settings_categories' });
    }

    send() {
        if (this.$route.params.categoryId) {
            this.sendEdit();

            return;
        }
        this.sendAdd();
    }

    sendEdit() {
        SettingsCategoryModule.sendEdit();
    }

    sendAdd() {
        SettingsCategoryModule.sendAdd({ name: 'settings_categories' });
    }

    initMask(): void {
        const settingsMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputsNumber = document.getElementsByClassName('j_number_mask_wrapper');
        const settingsMaskSlug = new Inputmask({ regex: slugRegExp, showMaskOnHover: false });
        const inputsSlug = document.getElementsByClassName('j_slug_mask_wrapper');

        for (const item of Object.values(inputsNumber)) {
            settingsMask.mask(item.getElementsByTagName('input')[0]);
        }

        for (const item of Object.values(inputsSlug)) {
            settingsMaskSlug.mask(item.getElementsByTagName('input')[0]);
        }
    }

    async mounted() {
        this.initMask();

        if (this.$route.params.categoryId) {
            await SettingsCategoryModule.initCardById(this.$route.params.categoryId);
            SettingsCategoryModule.updateIsLoading(false);
            this.returnTitleData = SettingsCategoryModule.titleReturnData.edit;

            return;
        }

        SettingsCategoryModule.updateIsLoading(false);
        this.returnTitleData = SettingsCategoryModule.titleReturnData.create;
    }

    beforeDestroy() {
        SettingsCategoryModule.reset();
    }
}
