import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import EntityBaseModule from '@/admin/store/entity';
import EntityModel from './entityModel';

import store from '@/admin/store';
import { createCityCard, editCityCard, getCityCard } from '@/admin/api/city';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import RedirectHandlerModule from '@/admin/store/modules/redirectHandler';
import { formatCreatedAt, getApiErrorMessage } from '@/lib/utils/Utils';
import SiteModule from '@/admin/store/site';
import { prepareLocalization, processLocalizationFromBackend } from '@/lib/utils/localization';

export const MODULE_NAME = 'settingsCityEntity';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SettingsCityModule extends EntityBaseModule {
    cardId: number | null = null;
    model: any;
    cityName = '';
    selectDefault = { id: 0, value: '' };
    titleReturnData = {
        edit: {
            icon: 'compass',
            title: this.cityName,
        },
        create: {
            icon: '',
            title: 'Добавление города',
        },
    };

    constructor(module: SettingsCityModule) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
    }

    @Mutation
    SET_CARD_ID(id: number | null) {
        this.cardId = id;
    }

    @Mutation
    SET_CITY_NAME(name: string) {
        this.cityName = name;
        this.titleReturnData.edit.title = name;
    }

    @Mutation
    SET_TABLE_SORT(sort: string) {
        this.model.sort.value = sort;
    }

    @Mutation
    SET_TIMEZONE(timezone: string) {
        this.model.timezone.value = timezone;
    }

    @Action({ rawError: true })
    async initCardById(cityId: number | string) {
        const result = await getCityCard(cityId);

        this.setInfo(result);
    }

    @Action({ rawError: true })
    async setInfo(data: any) {
        if (!data) {
            return;
        }

        this.context.commit('SET_CARD_ID', data.id);
        this.context.commit('SET_CITY_NAME', data.name);
        this.context.commit('SET_TABLE_SORT', data.sort);
        this.SET_TIMEZONE(data.timezone);
        this.context.commit('SET_CREATED_AT', await formatCreatedAt(data.createdAt));

        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: data.country?.name || '', type: 'country' });
        this.SET_SUGGESTIONS_SELECTED_BY_TYPE({
            selected: data.country?.id ? { id: data.country.id, value: data.country.name } : this.selectDefault,
            type: 'country',
        });

        const localization = processLocalizationFromBackend(data);
        this.SET_LOCALIZATION_BY_FIELD({ fieldName: 'name', data: localization });
    }

    @Action({ rawError: true })
    updateTableSort(params: string) {
        this.context.commit('SET_TABLE_SORT', params);
    }

    @Action({ rawError: true })
    updateTimezone(timezone: string) {
        this.RESET_ERROR_BY_FIELD('timezone');
        this.SET_TIMEZONE(timezone);
    }

    @Action({ rawError: true })
    validate() {
        const requiredFields = Object.keys(this.model).filter((key) => this.model[key].required === true);
        this.context.commit('RESET_ERROR_BY_FIELD', 'name');
        this.context.commit('RESET_ERROR_BY_FIELD', 'country');
        this.context.commit('RESET_ERROR_BY_FIELD', 'timezone');

        this.setValidateState({ index: 'name', field: this.model.name });
        this.setValidateSelect({ index: 'country', field: this.model.country });
        this.setValidateTimezone({ index: 'timezone', field: this.model.timezone });

        const fieldsWithError = requiredFields.filter((field) => this.model[field].error.class === 'cp-error');

        return fieldsWithError.length > 0 ? true : false;
    }

    @Action({ rawError: true })
    prepareRequestData() {
        let data: Record<string, string | number> = {
            name: this.model.name.localization.ru,
            country: this.model.country.current.id,
            timezone: this.model.timezone.value,
        };

        data = { ...data, ...prepareLocalization(this.model.name) };

        if (this.model.sort.value) {
            data['sort'] = this.model.sort.value;
        }

        return data;
    }

    @Action({ rawError: true })
    async sendEdit() {
        const isError = await this.validate();

        if (isError === true) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const formData = await this.prepareRequestData();
            let result: any;

            if (this.cardId != null) {
                result = await editCityCard(this.cardId, formData);
            }

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    async sendAdd(redirect: any = null) {
        const isError = await this.validate();

        if (isError === true) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const formData = await this.prepareRequestData();
            const result: any = await createCityCard(formData);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                RedirectHandlerModule.changeUrl(redirect);
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    reset() {
        this.context.commit('SET_IS_LOADING', true);
        this.context.commit('SET_CITY_NAME', '');
        this.context.commit('SET_TABLE_SORT', '');
        this.context.commit('SET_TIMEZONE', '');

        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: '', type: 'country' });
        this.SET_SUGGESTIONS_SELECTED_BY_TYPE({ selected: this.selectDefault, type: 'country' });
        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ list: [], type: 'country' });

        this.RESET_LOCALIZATION_BY_FIELD();

        SiteModule.SET_IS_BLOCK(false);

        this.resetErrorState();
    }

    @Action({ rawError: true })
    resetErrorState() {
        this.context.commit('RESET_ERROR_BY_FIELD', 'name');
        this.context.commit('RESET_ERROR_BY_FIELD', 'country');
        this.context.commit('RESET_ERROR_BY_FIELD', 'timezone');
    }
}

export default getModule(SettingsCityModule);
