import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';

import PageBaseModule from '@/admin/store/page';
import store from '@/admin/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';
import { getCountriesList } from '@/admin/api/country';
import filterModel from './filter';
import { Filter } from '@/lib/layouts/page/filter.interface';
import SettingsCountryFilter from './filterEntity';
import { FilterInputSuggestionsParamsInterface } from '@/lib/layouts/page/filterParams.interface';
import { processListForSelect } from '@/lib/utils/form';

export const MODULE_NAME = 'settingsCountry';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SettingsCountryModule extends PageBaseModule {
    filter: Filter;

    constructor(module: SettingsCountryModule) {
        super(module);
        const page = new PageEntity();

        page.setTitle('Страны');
        page.setSortName('settingsCountrySort');
        page.setDefaultSortField('name');
        this.pageSettings = page.values;

        const filter = new filterModel();
        this.filter = getModule(SettingsCountryFilter);
        this.filter.setTemplateClassName('template-sm');
        this.filter.setBtnClassName('row-5');
        this.filter.setFilterName('settingsCountryFilter');
        this.filter.setTemplateClassName('template-sm');
        this.filter.setFilterModel(filter.filterModel);
        this.filter.setFilterHandlers(filter.filterHandlers);
    }

    @Mutation
    SET_PAGE(number: number) {
        this.pageSettings.pageCurrent = number;
    }

    @Action({ rawError: true })
    async init() {
        this.setDefaultSort();

        await this.filter.init();

        this.getList();
    }

    @Action({ rawError: true })
    async getList() {
        this.SET_IS_LOADING(true);
        const sort = await this.getSortForRequest();
        const result = await getCountriesList(
            this.pageSettings.pageCurrent,
            sort,
            this.filter.filterSettings.filter,
            this.pageSettings.pageAmountItems,
        );

        await this.setList(result);
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async setList(table: TableApiInterface) {
        const tableProcessed = await this.prepareTable(table);

        this.context.commit('SET_TABLE', tableProcessed);
    }

    @Action({ rawError: true })
    prepareTable(table: TableApiInterface) {
        const hiddenColumns = Object.values(table.titles)
            .filter((title) => title.visible === false)
            .map((title) => title.id);
        hiddenColumns.push('sort');

        const rows: any = [];

        let index: string;
        let row: any;
        for ([index, row] of Object.entries(table.rows)) {
            const rowProcessed: any = {};
            for (const i of Object.keys(row)) {
                let cell: any = row[i];

                if (i === 'name') {
                    cell = `<span class="cp-table-column__accent">${cell}</span>`;
                }

                rowProcessed[i] = cell;
            }

            rows[index] = rowProcessed;
        }

        return {
            titles: table.titles,
            rows: rows,
            sortFields: table.sortFields,
            pagination: table.pagination ? table.pagination : {},
            totalItems: table.totalItems,
            filters: table.filters,
            hiddenColumns: hiddenColumns,
        } as TableInterface;
    }

    @Action({ rawError: true })
    async updateSuggestionsSelected(params: FilterInputSuggestionsParamsInterface) {
        await this.filter.updateSuggestionsSelected({
            ...params,
            value: { id: params.value.value, value: params.value.value },
        });

        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateSuggestionsValue(params: { key: string; value: string }) {
        this.filter.updateSuggestionsValue(params);

        const table = await getCountriesList(1, '', `filters[0][id]=name&filters[0][value]=${params.value}`, 50);
        this.filter.updateSuggestionsHintsList({ key: params.key, value: processListForSelect(table.rows) });
    }
}

export default getModule(SettingsCountryModule);
