/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class ShopsFilter {
    filterHandlers = {
        update: 'SettingsImportilter/updateFilter',
        reset: 'SettingsImportFilter/resetFilter',
        resetState: 'SettingsImportFilter/resetFilterState',
        removeSelected: 'SettingsImportFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        const filter = new FilterFactory();

        filter.createSelectEntity('type');
        filter.setTitle('type', 'По типу');
        filter.setName('type', 'select_type');
        filter.setPreviewName('type', 'Тип');
        filter.setAction('type', 'settingsImport/updateSelect');
        filter.setInitAction('type', 'settingsImport/initTypes');
        filter.setClearAction('type', '');
        filter.setIconClassName('type', 'icon-settings');
        filter.setClassName('type', 'col-1-row-1');

        filter.createDateIntervalPickerEntity('dateTime');
        filter.setName('dateTime', 'picker_date');
        filter.setTitle('dateTime', 'Время старта');
        filter.setPreviewName('dateTime', 'Вр. старта');
        filter.setDateIntervalList('dateTime', {
            startDate: {
                key: 'dateTimeStart',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'settingsImport/updateStartDate',
            },
            endDate: {
                key: 'dateTimeEnd',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'settingsImport/updateEndDate',
            },
        });
        filter.setClassName('dateTime', 'col-1-row-2');

        filter.createSearchEntity('auctionId');
        filter.setName('auctionId', 'search_auction_id');
        filter.setPlaceholder('auctionId', 'ID аукциона');
        filter.setIconClassName('auctionId', 'icon-store');
        filter.setAction('auctionId', 'settingsImport/updateSearch');
        filter.setPreviewName('auctionId', 'ID аукциона');
        filter.setClassName('auctionId', 'col-1-row-3');

        filter.createRadioEntity('publish');
        filter.setTitle('publish', 'Публиковать сразу');
        filter.setName('publish', 'radio_publish');
        filter.setPreviewName('publish', 'Публикация');
        filter.setList('publish', [
            { id: 0, name: 'Да', checked: false },
            { id: 1, name: '', checked: false },
            { id: 2, name: 'Нет', checked: false },
        ]);
        filter.setAction('publish', 'settingsImport/updateRadio');
        filter.setClassName('publish', 'col-1-row-4');

        this.filterModel = filter.filterModel;
    }
}
