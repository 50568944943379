
import { Vue, Component } from 'vue-property-decorator';

import ImportModule from '@/admin/store/settings/import';

import Loading from '@/components/Loading.vue';
import TableBlock from '@/components/table/Table.vue';
import WarningModal from '@/components/modules/Warning.vue';

@Component({
    components: {
        Loading,
        TableBlock,
        WarningModal,
    },
})
export default class SettingsImportTable extends Vue {
    importIdToDelete = 0;
    warningText = {
        yes: 'Удалить',
        description: `Вы уверены, что хотите удалить импорт?`,
    };

    get isLoading() {
        return ImportModule.isLoading;
    }

    get settings() {
        return ImportModule.pageSettings;
    }

    tableAction(params: any) {
        if (params.action === 'sort') {
            ImportModule.sort({ field: params.field, sort: params.sort });
        }

        if (params.action === 'delete') {
            this.importIdToDelete = +params.id;
        }
    }

    async deleteImport(value: number) {
        await ImportModule.deleteImport(value);
        ImportModule.getTable();

        this.importIdToDelete = 0;
    }

    closeModal() {
        this.importIdToDelete = 0;
    }

    created() {
        ImportModule.init();
    }

    beforeDestroy() {
        ImportModule.RESET_TABLE();
    }
}
