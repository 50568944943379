
import { Component, Vue, Prop } from 'vue-property-decorator';
import AddIcon from '@/components/icons/Add.vue';

@Component({
    components: {
        AddIcon,
    },
})
export default class TableTitle extends Vue {
    @Prop({ required: false }) readonly titles?: {};
    @Prop({ required: false }) readonly hiddenColumns?: [];
    @Prop({ required: false }) readonly sortFields?: [];
    @Prop({ required: false }) readonly isStatusColumn?: boolean;
    @Prop({ required: false }) readonly alignRightColumn?: [];

    addRow() {
        this.$emit('add');
    }

    toggleSort(id: string, sort: string) {
        this.$emit('toggle-sort', { action: 'sort', field: id, sort });
    }
}
