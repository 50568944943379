
import { Component, Watch, Mixins } from 'vue-property-decorator';

import SettingsGalleryModule from '@/admin/store/settings/auctions/gallery';

import CardMixin from './CardMixin';

import { textHours } from '@/admin/store/settings/auctions/texts';
import { declinateWord, formatNumberSpaced, formatRemoveSpaces } from '@/lib/utils/Utils';

import Inputmask from 'inputmask';
import { floatRegExp, numberRegExp } from '@/constants';

@Component({
    mixins: [CardMixin],
})
export default class SettingsAuctionsRulesGallery extends Mixins(CardMixin) {
    platformCommission = this.findName('platformCommission');
    timePay = this.findName('timePay');

    get settings() {
        return SettingsGalleryModule.settings;
    }

    get timePayText(): string {
        return String(this.timePay).length ? declinateWord(this.timePay, textHours) : textHours[2];
    }

    getNumberFormatted(value: string): string {
        return formatNumberSpaced(value);
    }

    updateTimePay(event: InputEvent): void {
        this.timePay.value = formatRemoveSpaces((event.target as HTMLInputElement).value);
    }

    findName(name: string) {
        const find = this.$store.state.settingGallery.settings.find((item: any) => {
            return item.name === name;
        });

        return Object.assign({}, find);
    }

    initMask() {
        const settingsMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputs = this.$el.getElementsByClassName('j_number_mask_wrapper');

        for (const item of Object.values(inputs)) {
            settingsMask.mask(item.getElementsByTagName('input')[0]);
        }

        const floatMask = new Inputmask({ regex: floatRegExp, showMaskOnHover: false });
        const inputsFloat = this.$el.getElementsByClassName('j_float_mask_wrapper');

        for (const item of Object.values(inputsFloat)) {
            floatMask.mask(item.getElementsByTagName('input')[0]);
        }
    }

    @Watch('saveCounter')
    onChangeSaveCounter() {
        SettingsGalleryModule.setItem({
            name: 'platformCommission',
            value: this.platformCommission.value,
        });
        SettingsGalleryModule.setItem({ name: 'timePay', value: this.timePay.value });
    }

    mounted() {
        this.initMask();
    }
}
