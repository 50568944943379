
import { Component, Vue } from 'vue-property-decorator';
import FlexLayout from '@/admin/layouts/FlexLayout.vue';
import Loading from '@/components/Loading.vue';
import TableBlock from '@/components/table/Table.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';

// import FilterModule from '@/admin/store/filter';
import SettingsModule from '@/admin/store/settings';
import SettingsCountryModule from '@/admin/store/settings/country';

@Component({
    components: {
        FlexLayout,
        Loading,
        TableBlock,
        AppHeaderMain,
        TabsNav,
        TitleReturn,
    },
})
export default class ContentCountriesMain extends Vue {
    get settings() {
        return SettingsCountryModule.pageSettings;
    }

    get filterSettings() {
        return SettingsCountryModule.filter.filterSettings;
    }

    get isLoading(): boolean {
        return SettingsCountryModule.isLoading;
    }

    get tabsNav() {
        return SettingsModule.tabsNav;
    }

    get currentTab() {
        return SettingsModule.allTabs.countries.id;
    }

    get paginationAction() {
        return SettingsCountryModule.updatePage;
    }

    get paginationItems(): { action: (value: string | number) => void; items: string } {
        return {
            action: SettingsCountryModule.updateItems,
            items: SettingsCountryModule.pageSettings.pageAmountItems,
        };
    }

    tableAction(params: any) {
        if (params.action === 'sort') {
            SettingsCountryModule.sort({ field: params.field, sort: params.sort });
        }
    }

    updateList() {
        SettingsCountryModule.getList();
    }

    async resetFilters() {
        await SettingsCountryModule.clearSort();
        await SettingsCountryModule.filter.resetFilter();
        SettingsCountryModule.getList();
    }

    toAdd() {
        this.$router.push({ name: 'settings_country_create' });
    }

    mounted(): void {
        SettingsCountryModule.init();
        SettingsModule.initTabsItems();
    }

    beforeDestroy(): void {
        SettingsCountryModule.reset();
    }
}
