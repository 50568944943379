import InputEntity from '@/lib/formFactory/inputEntity';
import CheckboxEntity from '@/lib/formFactory/checkboxEntity';
import ImageInputEntity from '@/lib/formFactory/imageInputEntity';
import InputSuggestionEntity from '@/lib/formFactory/inputSuggestionsEntity';

export default class EntityModel {
    model: any;

    constructor() {
        const nameEntity = new InputEntity('name');
        nameEntity.setTitle('Название категории');
        nameEntity.initLocalization();

        const descriptionEntity = new InputEntity('description');
        descriptionEntity.setTitle('Описание');
        descriptionEntity.initLocalization();

        const parentCategoryEntity = new InputSuggestionEntity('parentCategory');
        parentCategoryEntity.setTitle('Родительская категория');

        const sortEntity = new InputEntity('sort');
        sortEntity.setTitle('Порядок сортировки');

        const slugEntity = new InputEntity('slug');
        slugEntity.setTitle('Slug');

        const isActiveEntity = new CheckboxEntity('isActive');
        isActiveEntity.setList({
            0: {
                id: 1,
                name: 'Активен',
                checked: false,
            },
        });

        const photoEntity = new ImageInputEntity('photo');
        photoEntity.setTitle('Фото');

        this.model = {
            name: nameEntity.entity[nameEntity.entityName],
            description: descriptionEntity.entity[descriptionEntity.entityName],
            parentCategory: parentCategoryEntity.entity[parentCategoryEntity.entityName],
            sort: sortEntity.entity[sortEntity.entityName],
            slug: slugEntity.entity[slugEntity.entityName],
            isActive: isActiveEntity.entity[isActiveEntity.entityName],
            createdAt: '',
            createdBy: { id: null, name: '' },
            photo: photoEntity.entity[photoEntity.entityName],
            categoryLevel: {
                value: 0,
            },
        };
    }
}
