
import { Component } from 'vue-property-decorator';
import TableBlock from '@/components/table/Table.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';

import BtnDefault from '@/components/BtnDefault.vue';
import InputBlock from '@/components/form/InputMain.vue';
import InputLocalization from '@/components/input/InputLocalization.vue';
import LocalizationLayout from '@/admin/layouts/LocalizationLayout.vue';
import SelectCustom from '@/components/select/SelectCustom.vue';
import ImageBlock from '@/components/imageInput/ImageInput.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import Loading from '@/components/Loading.vue';
import InputSuggestions from '@/components/input/InputSuggestions.vue';

import Inputmask from 'inputmask';
import SettingsCityModule from '@/admin/store/settings/city/entity';
import RemoveModal from '@/components/modules/removeModal.vue';
import { formatNumberSpaced, formatRemoveSpaces } from '@/lib/utils/Utils';
import { numberRegExp } from '@/constants';
import { LocalizationParams } from '@/interfaces/localization';

@Component({
    components: {
        RemoveModal,
        TableBlock,
        AppHeaderMain,
        TabsNav,
        TitleReturn,
        BtnDefault,
        InputBlock,
        InputLocalization,
        SelectCustom,
        BtnSubmit,
        ImageBlock,
        Loading,
        InputSuggestions,
    },
})
export default class SettingsCityCard extends LocalizationLayout {
    returnTitleData = {
        icon: '',
        title: '',
    };
    returnTitleIcon = 'compass';

    get model() {
        return SettingsCityModule.model;
    }

    get cityName() {
        return SettingsCityModule.cityName;
    }

    get isLoading() {
        return SettingsCityModule.isLoading;
    }

    getFormattedNumberString(value: string | number): string {
        return formatNumberSpaced(value);
    }

    handleName(params: LocalizationParams): void {
        SettingsCityModule.updateLocalizationField({ ...params, field: 'name' });
    }

    handleSort(value: string) {
        SettingsCityModule.updateTableSort(formatRemoveSpaces(value));
    }

    searchCountry(search: string) {
        SettingsCityModule.searchCountry(search);
    }

    updateCountry(selected: { id: number; value: string }) {
        SettingsCityModule.updateSuggestionsSelectedByType({ selected, type: 'country' });
    }

    updateTimezone(timezone: string) {
        SettingsCityModule.updateTimezone(timezone);
    }

    returnBefore() {
        this.$router.push({ name: 'settings_cities' });
    }

    send() {
        if (this.$route.params.cityId) {
            this.sendEdit();

            return;
        }
        this.sendAdd();
    }

    sendEdit() {
        SettingsCityModule.sendEdit();
    }

    sendAdd() {
        SettingsCityModule.sendAdd({ name: 'settings_cities' });
    }

    initMask() {
        const settingsMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputsNumber = document.getElementsByClassName('j_number_mask_wrapper');
        const timezoneMask = new Inputmask({ mask: this.model.timezone.mask });
        const inputsTimezone = this.$el.getElementsByClassName('j_timezone_mask_wrapper');

        for (const item of Object.values(inputsNumber)) {
            settingsMask.mask(item.getElementsByTagName('input')[0]);
        }

        for (const item of Object.values(inputsTimezone)) {
            timezoneMask.mask(item.getElementsByTagName('input')[0]);
        }
    }

    async mounted() {
        this.initMask();

        if (this.$route.params.cityId) {
            await SettingsCityModule.initCardById(this.$route.params.cityId);
            SettingsCityModule.updateIsLoading(false);
            this.returnTitleData = SettingsCityModule.titleReturnData.edit;

            return;
        }

        SettingsCityModule.updateIsLoading(false);
        this.returnTitleData = SettingsCityModule.titleReturnData.create;
    }

    beforeDestroy() {
        SettingsCityModule.reset();
    }
}
