
import { Component, Watch, Mixins } from 'vue-property-decorator';

import IconFont from '@/components/icons/IconFont.vue';
import TableBlock from '@/components/table/TableInputs.vue';

import SettingsActionModule from '@/admin/store/settings/auctions';

import CardMixin from './CardMixin';
import { TableRow } from '@/lib/layouts/page/table.interface';
import { declinateWord, formatNumberSpaced, formatRemoveSpaces } from '@/lib/utils/Utils';
import { textHours, textSeconds } from '@/admin/store/settings/auctions/texts';
import { numberRegExp } from '@/constants';
import Inputmask from 'inputmask';

interface StepsTableRow {
    id: number;
    from: string | { value: string };
    input_to?: string | number;
    input_plainStep: string;
    is_input_plainStep: boolean;
    input_percentStep: string;
    is_input_percentStep: boolean;
    _actions: string[];
}

@Component({
    mixins: [CardMixin],
    components: {
        IconFont,
        TableBlock,
    },
})
export default class SettingsAuctionsRulesAH extends Mixins(CardMixin) {
    timeAutoTrade = this.findName('timeAutoTrade');
    termInvoicePayment = this.findName('termInvoicePayment');
    bidStepsFromStartPrice = this.setBidStepsFromStartPrice('bidStepsFromStartPrice');
    postAuctionSaleOfLotsHours = this.findName('postAuctionSaleOfLotsHours');

    hiddenColumns = ['id', 'is_input_plainStep', 'is_input_percentStep'];

    stepsTable: { name: string; rows: StepsTableRow[] } = {
        name: 'steps',
        rows: this.bidStepsFromStartPrice.value,
    };

    defaultRows = [
        {
            id: 0,
            from: '0',
            input_to: '1',
            input_plainStep: '0',
            is_input_plainStep: true,
            input_percentStep: '0',
            is_input_percentStep: false,
            _actions: [],
        },
    ];

    get termInvoicePaymentText(): string {
        return String(this.termInvoicePayment).length
            ? declinateWord(this.termInvoicePayment, textHours)
            : textHours[2];
    }

    get timeAutoTradeText(): string {
        return String(this.timeAutoTrade).length ? declinateWord(this.timeAutoTrade, textSeconds) : textSeconds[2];
    }

    getNumberFormatted(value: string): string {
        return formatNumberSpaced(value);
    }

    updateTimeAutoTrade(event: InputEvent): void {
        this.timeAutoTrade.value = this.getNumberFormatted((event.target as HTMLInputElement).value);
    }

    updateTermInvoicePayment(event: InputEvent): void {
        this.termInvoicePayment.value = this.getNumberFormatted((event.target as HTMLInputElement).value);
    }

    updatePostAuctionSaleOfLotsHours(event: InputEvent): void {
        this.postAuctionSaleOfLotsHours.value = this.getNumberFormatted((event.target as HTMLInputElement).value);
    }

    updateStepType(params: { id: number; value: string }): void {
        this.stepsTable.rows = this.stepsTable.rows.map((row, index) => {
            if (index !== params.id) {
                return row;
            }

            if (params.value === 'plainStep') {
                row['is_input_plainStep'] = true;
                row['is_input_percentStep'] = false;
            }

            if (params.value === 'percentStep') {
                row['is_input_percentStep'] = true;
                row['is_input_plainStep'] = false;
            }

            return row;
        });

        this.validateSteps();
    }

    updateStepValue(params: { id: number; value: number }): void {
        this.stepsTable.rows = this.stepsTable.rows.map((row, index) => {
            if (index !== params.id) {
                return row;
            }

            if (row['is_input_plainStep'] === true) {
                row['input_plainStep'] = formatNumberSpaced(params.value);
            }

            if (row['is_input_percentStep'] === true) {
                row['input_percentStep'] = formatNumberSpaced(params.value);
            }

            return row;
        });

        this.validateSteps();
    }

    tableAction(params: any): void {
        if (params.action === 'radio') {
            const paramsArr = params.data.id.split('_');
            this.updateStepType({ id: Number(paramsArr[0]), value: paramsArr[2] });
        }

        if (params.action === 'delete') {
            this.deleteItem(params.id);
        }
    }

    inputHandler(params: any): void {
        if (params.action === 'input_to') {
            this.updateToValue({ id: params.id, value: params.data });
        }

        if (params.action === 'from') {
            this.updateFromValue({ id: params.id, value: params.data });
        }

        if (params.action === 'input_plainStep' || params.action === 'input_percentStep') {
            const value = +formatRemoveSpaces(params.data);

            if (!isFinite(value)) {
                return;
            }

            this.updateStepValue({ id: params.id, value: value });
        }
    }

    focusoutHandler(params: any): void {
        if (params.action === 'input_to') {
            const value = formatRemoveSpaces(params.data);

            if (!isFinite(+value)) {
                return;
            }

            this.updateToValueChange({ id: params.id, value });
        }

        if (params.action === 'from') {
            const value = formatRemoveSpaces(params.data);

            if (!isFinite(+value)) {
                return;
            }

            this.updateFromValueChange({ id: params.id, value });
        }
    }

    updateToValue(params: { id: number; value: string }): void {
        this.stepsTable.rows = this.stepsTable.rows.map((row) => {
            if (row.id !== params.id) {
                return row;
            }

            row.input_to = params.value.length ? formatNumberSpaced(params.value) : params.value;

            return row;
        });
    }

    async updateToValueChange(params: { id: number; value: string }): Promise<void> {
        await this.updateToValue(params);
        this.validateSteps();
    }

    updateFromValue(params: { id: number; value: string }): void {
        this.stepsTable.rows = this.stepsTable.rows.map((row) => {
            if (row.id !== params.id) {
                return row;
            }

            (row.from as { value: string }).value = params.value.length
                ? formatNumberSpaced(params.value)
                : params.value;

            return row;
        });
    }

    async updateFromValueChange(params: { id: number; value: string }): Promise<void> {
        await this.updateFromValue(params);
        this.validateSteps();
    }

    validateSteps(): void {
        let prevRow: any;
        this.stepsTable.rows = this.stepsTable.rows.map(
            (row: StepsTableRow, index: number, tableRows: StepsTableRow[]) => {
                let fromValue = +formatRemoveSpaces(
                    String(typeof row.from === 'object' ? (row.from as { value: string }).value : row.from),
                );
                let toValue = +formatRemoveSpaces(String(row.input_to));

                if (prevRow) {
                    fromValue = +prevRow.input_to + 1;
                }

                if (index === 0) {
                    if (fromValue >= toValue) {
                        toValue = fromValue + 1;
                    }

                    prevRow = {
                        ...row,
                        from: fromValue,
                        input_to: toValue,
                    };

                    return {
                        ...row,
                        from: { value: formatNumberSpaced(fromValue) },
                        input_to: formatNumberSpaced(toValue),
                    };
                }

                const isLastRow = index === tableRows.length - 1;
                if (!isLastRow) {
                    toValue = fromValue >= toValue ? fromValue + 1 : toValue;
                }

                row['_actions'] = index === 0 ? [] : ['delete'];

                prevRow = {
                    ...row,
                    from: fromValue,
                    input_to: toValue,
                };

                return {
                    ...row,
                    from: formatNumberSpaced(fromValue),
                    input_to: formatNumberSpaced(toValue),
                };
            },
        );
    }

    addItem(): void {
        this.stepsTable.rows.push({
            id: this.stepsTable.rows.length || 0,
            from: '0',
            input_to: 0,
            input_plainStep: '0',
            is_input_plainStep: true,
            input_percentStep: '0',
            is_input_percentStep: false,
            _actions: this.stepsTable.rows.length > 0 ? ['delete'] : [],
        });

        this.validateSteps();
    }

    addEmptyRow(): void {
        this.stepsTable.rows = [
            {
                id: 0,
                from: '0',
                input_to: '',
                input_plainStep: '',
                is_input_plainStep: true,
                input_percentStep: '',
                is_input_percentStep: false,
                _actions: [],
            },
        ];

        this.validateSteps();
    }

    setBidStepsFromStartPrice(name: string): { value: StepsTableRow[] } {
        let bidStepsFromStartPrice = this.findJsonName(name);

        bidStepsFromStartPrice.value = bidStepsFromStartPrice.value.length
            ? bidStepsFromStartPrice.value.map((item: any, index: number) => {
                  return {
                      id: index,
                      from:
                          index === 0
                              ? {
                                    value: formatNumberSpaced(item.from ?? 0),
                                }
                              : formatNumberSpaced(item.from ?? 0),
                      input_to: formatNumberSpaced(item.to ?? item.from + 1),
                      input_plainStep: formatNumberSpaced(item.fix.value),
                      is_input_plainStep: item.fix.checked,
                      input_percentStep: item.percent.value,
                      is_input_percentStep: item.percent.checked,
                      _actions: ['delete'],
                  };
              })
            : this.defaultRows;

        return bidStepsFromStartPrice;
    }

    deleteItem(rowIdToDelete: number): void {
        const rows = this.stepsTable.rows.filter((row: StepsTableRow) => row.id !== rowIdToDelete);
        this.stepsTable.rows = rows;

        if (!this.stepsTable.rows.length) {
            this.addEmptyRow();
        }

        this.validateSteps();
    }

    @Watch('saveCounter')
    onChangeSaveCounter(): void {
        SettingsActionModule.setItem({ name: 'timeAutoTrade', value: +formatRemoveSpaces(this.timeAutoTrade.value) });
        SettingsActionModule.setItem({
            name: 'termInvoicePayment',
            value: +formatRemoveSpaces(this.termInvoicePayment.value),
        });
        SettingsActionModule.setItem({
            name: 'postAuctionSaleOfLotsHours',
            value: +formatRemoveSpaces(this.postAuctionSaleOfLotsHours.value),
        });

        const table = ((this.stepsTable.rows as unknown) as TableRow[]).map(
            (row: TableRow, index: number, rows: TableRow[]) => {
                const newRow: any = {
                    id: row.id,
                    from:
                        index === 0
                            ? +formatRemoveSpaces((row.from as { value: string }).value as string)
                            : +formatRemoveSpaces(row.from as string),
                    fix: {
                        checked: row.is_input_plainStep,
                        value: +formatRemoveSpaces(row.input_plainStep as string),
                    },
                    percent: {
                        checked: row.is_input_percentStep,
                        value: row.input_percentStep,
                    },
                    _actions: row._actions,
                };

                if (index !== rows.length - 1) {
                    newRow.to = +formatRemoveSpaces(row.input_to as string);
                }

                return newRow;
            },
        );

        SettingsActionModule.setItem({ name: 'bidStepsFromStartPrice', value: JSON.stringify(table) });
    }

    mounted(): void {
        this.titlesTable[this.titlesTable.length - 1].name = '%';

        const numberMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputsNumber = this.$el.getElementsByClassName('j_num_mask_wrapper');

        for (const item of Object.values(inputsNumber)) {
            numberMask.mask(item.getElementsByTagName('input')[0]);
        }
    }
}
