import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';

import PageBaseModule from '@/admin/store/page';
import store from '@/admin/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';
import { getCitiesList } from '@/admin/api/city';

import { getCountriesList } from '@/admin/api/country';

import filterModel from './filter';
import { Filter } from '@/lib/layouts/page/filter.interface';
import SettingsCityFilter from './filterEntity';
import { FilterInputSuggestionsParamsInterface } from '@/lib/layouts/page/filterParams.interface';
import { processListForSelect } from '@/lib/utils/form';

export const MODULE_NAME = 'settingsCity';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SettingsCityModule extends PageBaseModule {
    filter: Filter;

    constructor(module: SettingsCityModule) {
        super(module);
        const page = new PageEntity();

        page.setTitle('Города');
        page.setSortName('settingsCitySort');
        page.setDefaultSortField('name');
        this.pageSettings = page.values;

        const filter = new filterModel();
        this.filter = getModule(SettingsCityFilter);
        this.filter.setTemplateClassName('template-sm');
        this.filter.setBtnClassName('row-5');
        this.filter.setFilterName('settingsCityFilter');
        this.filter.setTemplateClassName('template-sm');
        this.filter.setFilterModel(filter.filterModel);
        this.filter.setFilterHandlers(filter.filterHandlers);
    }

    @Mutation
    SET_PAGE(number: number) {
        this.pageSettings.pageCurrent = number;
    }

    @Action({ rawError: true })
    async init() {
        this.setDefaultSort();

        this.filter.init();

        this.getList();
    }

    @Action({ rawError: true })
    async getList() {
        this.SET_IS_LOADING(true);

        const sort = await this.getSortForRequest();
        const result = await getCitiesList(
            this.pageSettings.pageCurrent,
            sort,
            this.filter.filterSettings.filter,
            this.pageSettings.pageAmountItems,
        );

        await this.setList(result);
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async setList(table: TableApiInterface) {
        const tableProcessed = await this.prepareTable(table);

        this.context.commit('SET_TABLE', tableProcessed);
    }

    @Action({ rawError: true })
    async removeSelectedFilter(params: any) {
        if (params === 'country') {
            await this.filter.resetSelect('name');
            await this.filter.resetSelect('country');

            await this.context.commit('SettingsCityFilter/DEL_FILTER_PREVIEW', 'country', { root: true });
            await this.context.commit('SettingsCityFilter/DEL_FILTER_PREVIEW', 'name', { root: true });

            this.filter.updateFilter();

            return;
        }

        this.filter.removeSelectedFilter(params);
    }

    @Action({ rawError: true })
    prepareTable(table: TableApiInterface) {
        const hiddenColumns = Object.values(table.titles)
            .filter((title) => title.visible === false)
            .map((title) => title.id);
        hiddenColumns.push('sort');

        const rows: any = [];

        let index: string;
        let row: any;
        for ([index, row] of Object.entries(table.rows)) {
            const rowProcessed: any = {};
            for (const i of Object.keys(row)) {
                let cell: any = row[i];

                if (i === 'name') {
                    cell = `<span class="cp-table-column__accent">${cell}</span>`;
                }

                if (i === 'country') {
                    cell = `<span class="cp-table-column__bold">${cell}</span>`;
                }

                rowProcessed[i] = cell;
            }

            rows[index] = rowProcessed;
        }

        return {
            titles: table.titles,
            rows: rows,
            sortFields: table.sortFields,
            pagination: table.pagination ? table.pagination : {},
            totalItems: table.totalItems,
            filters: table.filters,
            hiddenColumns: hiddenColumns,
        } as TableInterface;
    }

    @Action({ rawError: true })
    async updateSuggestionsSelected(params: FilterInputSuggestionsParamsInterface) {
        const paramsProcessed =
            params.key === 'name'
                ? { ...params, value: { id: params.value.value, value: params.value.value } }
                : params;
        await this.filter.updateSuggestionsSelected(paramsProcessed);

        if (params.key === 'country') {
            await this.filter.updateSuggestionsSelected({ key: 'name', value: { id: 0, value: '' } });
        }

        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateCountrySuggestionsValue(params: { key: string; value: string }) {
        this.filter.updateSuggestionsValue(params);

        const table = await getCountriesList(1, '', `filters[0][id]=name&filters[0][value]=${params.value}`, 50);
        this.filter.updateSuggestionsHintsList({ key: params.key, value: processListForSelect(table.rows) });
    }

    @Action({ rawError: true })
    async updateCitySuggestionsValue(params: { key: string; value: string }) {
        this.filter.updateSuggestionsValue(params);

        let requestFilter = `filters[0][id]=name&filters[0][value]=${params.value}`;
        requestFilter += this.filter.filterSettings.filterModel.country.current?.id
            ? `&filters[1][id]=country&filters[1][value]=${this.filter.filterSettings.filterModel.country.current.id}`
            : '';

        const table = await getCitiesList(1, '', requestFilter, 50);
        this.filter.updateSuggestionsHintsList({ key: params.key, value: processListForSelect(table.rows) });
    }
}

export default getModule(SettingsCityModule);
