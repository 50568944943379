import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import EntityBaseModule from '@/admin/store/entity';
import EntityModel from './entityModel';

import store from '@/admin/store';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import {
    createCategoryCard,
    editCategoryCard,
    getCategoryList,
    getCategoryCard,
    getCategoryAll,
} from '@/admin/api/category';
import {
    formatCreatedAt,
    generateFilter,
    generateSort,
    getApiErrorMessage,
    imageFileToBase64,
} from '@/lib/utils/Utils';
import RedirectHandlerModule from '@/admin/store/modules/redirectHandler';
import { processListForSelect } from '@/lib/utils/form';
import { CategoryInterface } from '@/interfaces/category.interface';
import SiteModule from '@/admin/store/site';
import { prepareLocalization, processLocalizationFromBackend } from '@/lib/utils/localization';

export const MODULE_NAME = 'settingsCategoryEntity';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SettingsCategoryModule extends EntityBaseModule {
    cardId: number | null = null;
    model: any;
    categoryName = '';
    categories: CategoryInterface[] = [];
    selectDefault = { id: 0, value: '' };
    titleReturnData = {
        edit: {
            icon: 'book',
            title: this.categoryName,
        },
        create: {
            icon: '',
            title: 'Добавление категории',
        },
    };

    constructor(module: SettingsCategoryModule) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
    }

    @Mutation
    SET_CARD_ID(id: number | null) {
        this.cardId = id;
    }

    @Mutation
    SET_NAME(name: string) {
        this.model.name.value = name;
    }

    @Mutation
    SET_CATEGORY_NAME(name: string) {
        this.categoryName = name;
        this.titleReturnData.edit.title = name;
    }

    @Mutation
    SET_COUNTRY_LIST(list: { id: number; value: string }[]) {
        this.model.country.list = list;
    }

    @Mutation
    SET_COUNTRY(country: { id: number; value: string }) {
        this.model.country.current = country;
    }

    @Mutation
    SET_SORT_ORDER(sort: string) {
        this.model.sort.value = sort;
    }

    @Mutation
    SET_DESCRIPTION(description: string) {
        this.model.description.value = description;
    }

    @Mutation
    SET_CATEGORIES(list: CategoryInterface[]) {
        this.categories = list;
    }

    @Mutation
    SET_IS_ACTIVE(bool: boolean) {
        this.model.isActive.list[0].checked = bool;
    }

    @Mutation
    SET_PHOTO(value: string) {
        this.model.photo.value = value;
    }

    @Mutation
    SET_PHOTO_FILE(file: File) {
        this.model.photo.file = file;
    }

    @Mutation
    SET_PHOTO_PREVIEW(base64String: string) {
        this.model.photo.preview = base64String;
    }

    @Mutation
    SET_CATEGORY_LEVEL(level: number) {
        this.model.categoryLevel.value = level;
    }

    @Action({ rawError: true })
    async initForm() {
        this.SET_CATEGORIES(await getCategoryAll());
    }

    @Action({ rawError: true })
    async initCardById(categoryId: number | string) {
        const result = await getCategoryCard(categoryId);

        this.setInfo(result);
    }

    @Action({ rawError: true })
    setInfo(data: any) {
        if (!data) {
            return;
        }

        this.context.commit('SET_CARD_ID', data.id);
        this.context.commit('SET_CATEGORY_NAME', data.name);
        this.context.commit('SET_SORT_ORDER', data.sort);
        this.context.commit('SET_PHOTO', data.photo ? data.photo : '');
        this.context.commit('SET_IS_ACTIVE', data.isActive ? data.isActive : false);
        this.context.commit('SET_CATEGORY_LEVEL', data.categoryLevel);
        this.SET_MODEL_FIELD_VALUE({ field: 'slug', value: data.slug || '' });

        if (data.parentCategory) {
            this.updateSuggestionsSelectedByType({
                selected: { id: data.parentCategory.id, value: data.parentCategory.name },
                type: 'parentCategory',
            });
        }

        if (data.createdByUser) {
            const createdAt = formatCreatedAt(data.createdByUser.createdAt);
            this.context.commit('SET_CREATED_AT', createdAt);

            const createdByName = [
                data.createdByUser.firstName,
                data.createdByUser.middleName,
                data.createdByUser.lastName,
            ].join(' ');
            this.context.commit('SET_CREATED_BY', { id: data.createdByUser.id, name: createdByName });
        }

        this.SET_LOCALIZATION_BY_FIELD({ fieldName: 'name', data: processLocalizationFromBackend(data) });
        this.SET_LOCALIZATION_BY_FIELD({
            fieldName: 'description',
            data: processLocalizationFromBackend(data, 'description'),
        });
    }

    @Action({ rawError: true })
    updateParentCategory(category: { id: number; value: string }) {
        this.context.commit('SET_PARENT_CATEGORY', category);
    }

    @Action({ rawError: true })
    updateIsActive(bool: boolean) {
        this.context.commit('SET_IS_ACTIVE', bool);
    }

    @Action({ rawError: true })
    updateSortOrder(sort: string) {
        this.context.commit('SET_SORT_ORDER', sort);
    }

    @Action({ rawError: true })
    async updatePhotoFile(file: File) {
        this.context.commit('SET_PHOTO_FILE', file);

        const preview = await imageFileToBase64(file);
        this.context.commit('SET_PHOTO_PREVIEW', preview);
    }

    @Action({ rawError: true })
    removeImagePreview() {
        this.context.commit('SET_PHOTO_FILE', null);
        this.context.commit('SET_PHOTO_PREVIEW', '');
    }

    @Action({ rawError: true })
    async searchParentCategory(search: string) {
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: search, type: 'parentCategory' });
        const filtersArray = [{ id: 'noThirdLevel', value: '1' }];

        if (search) {
            filtersArray.push({ id: 'name', value: search });
        }

        const table = await getCategoryList(
            1,
            await generateSort({ id: 'name', value: 'ASC' }),
            await generateFilter(filtersArray),
            50,
        );
        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({
            list: await processListForSelect(table.rows),
            type: 'parentCategory',
        });
    }

    @Action({ rawError: true })
    validate() {
        let isError = false;
        this.context.commit('RESET_ERROR_BY_FIELD', 'name');

        this.setValidateState({ index: 'name', field: this.model.name });

        if (!this.model.name.localization.ru) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: 'name', message: '' });
            isError = true;
        }

        return isError;
    }

    @Action({ rawError: true })
    prepareRequestData() {
        let data: Record<string, string | number> = {
            name: this.model.name.localization.ru,
            isActive: this.model.isActive.list[0].checked ? 1 : 0,
            slug: this.model.slug.value,
        };

        data = {
            ...data,
            ...prepareLocalization(this.model.name),
            ...prepareLocalization(this.model.description, 'description'),
        };

        if (this.model.sort.value) {
            data['sort'] = this.model.sort.value;
        }

        if (this.model.description.localization.ru) {
            data['description'] = this.model.description.localization.ru;
        }

        if (this.model.parentCategory.current.id) {
            data['parentCategory'] = this.model.parentCategory.current.id;
        }

        if (this.model.photo.file) {
            data['photo'] = this.model.photo.file;
        }

        return data;
    }

    @Action({ rawError: true })
    async sendEdit() {
        const isError = await this.validate();

        if (isError === true) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const formData = await this.prepareRequestData();

            let result: any;

            if (this.cardId != null) {
                result = await editCategoryCard(this.cardId, formData);
            }

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });

                this.context.commit('SET_PHOTO', result.data.result.item.photo);
                this.context.commit('SET_PHOTO_FILE', null);
                this.context.commit('SET_PHOTO_PREVIEW', '');
                
                SiteModule.SET_IS_BLOCK(false);
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    async sendAdd(redirect: any = null) {
        const isError = await this.validate();

        if (isError === true) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const formData = await this.prepareRequestData();
            const result: any = await createCategoryCard(formData);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                RedirectHandlerModule.changeUrl(redirect);
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    reset() {
        this.context.commit('SET_IS_LOADING', true);

        this.context.commit('SET_CARD_ID', null);
        this.context.commit('SET_CATEGORY_NAME', '');
        this.context.commit('SET_SORT_ORDER', '');
        this.context.commit('SET_DESCRIPTION', '');
        this.context.commit('SET_PHOTO', '');
        this.context.commit('SET_PHOTO_FILE', null);
        this.context.commit('SET_PHOTO_PREVIEW', '');
        this.context.commit('SET_IS_ACTIVE', false);
        this.context.commit('SET_CATEGORY_LEVEL', 0);
        this.SET_MODEL_FIELD_VALUE({ field: 'slug' });

        this.resetSuggestionsStateByType('parentCategory');

        this.context.commit('SET_CREATED_AT', '');
        this.context.commit('SET_CREATED_BY', { id: null, name: '' });
        this.RESET_LOCALIZATION_BY_FIELD();

        SiteModule.SET_IS_BLOCK(false);

        this.resetErrorState();
    }

    @Action({ rawError: true })
    resetErrorState() {
        this.context.commit('RESET_ERROR_BY_FIELD', 'name');
    }
}

export default getModule(SettingsCategoryModule);
