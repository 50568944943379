
import { Mixins, Component, Prop } from 'vue-property-decorator';

import TableTitle from './_tableTitleAdd.vue';
import IconFont from '@/components/icons/IconFont.vue';
import PaginationBlock from '@/components/Pagination.vue';
import CheckboxCustom from '@/components/form/Checkbox.vue';
import InputSuggestions from '@/components/input/InputSuggestions.vue';
import SuggestionsTree from '@/components/suggestions/Tree.vue';

import tableMixin from './tableMixin';

import moment from 'moment/moment.js';
import InputSuggestionEntity from '@/lib/formFactory/inputSuggestionsEntity';
import { InputSuggestion, InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { processCategoryListForSuggestionsMultiple } from '@/lib/utils/form';
import { capitaize, formatNumberSpaced, formatRemoveSpaces, generateFilter, generateSort } from '@/lib/utils/Utils';
import { getCategoryHierarchyList } from '@/admin/api/category';

moment.locale('ru');

@Component({
    components: {
        TableTitle,
        IconFont,
        PaginationBlock,
        CheckboxCustom,
        InputSuggestions,
        SuggestionsTree,
    },
})
export default class TableActionsComponent extends Mixins(tableMixin) {
    @Prop() readonly depend!: any;
    @Prop() readonly categories!: any;

    categoryEntity: InputSuggestion | null = null;

    getNumberFormatted(value: string | number): string {
        return formatNumberSpaced(value);
    }

    parseDate(date: string) {
        return moment(date).format('HH:mm МСК DD MMMM YYYY');
    }

    add() {
        this.$emit('add');
    }

    onChange(event: InputEvent, rowData: { fieldName: string; rowId: number; value: string; row: any }) {
        this.$emit('click', {
            action: 'input',
            data: {
                rowId: rowData.rowId,
                value: formatRemoveSpaces((event.target as HTMLInputElement).value),
                fieldName: rowData.fieldName,
            },
        });
    }

    checkboxUpdate(
        event: { bool: boolean; data_value: string; id: string },
        data: { fieldName: string; rowId: number; value: any },
    ) {
        if (!Array.isArray(this.rows)) return;

        this.$emit('click', {
            action: 'check',
            data: { fieldName: data.fieldName, rowId: data.rowId, bool: event.bool },
        });
    }

    async searchCategory(search: string): Promise<void> {
        (this.categoryEntity as InputSuggestion).value = search;

        const filter = search ? generateFilter({ id: 'name', value: capitaize(search) }) : '';

        const categoriesTree = await getCategoryHierarchyList(
            1,
            generateSort({ id: 'name', value: 'ASC' }),
            filter,
            50,
        );

        (this.categoryEntity as InputSuggestion).hintsList = processCategoryListForSuggestionsMultiple(
            categoriesTree,
            'name',
            'categories',
        );
    }

    updateSelectedCategory(selected: InputSuggestionItem, rowId: number) {
        (this.categoryEntity as InputSuggestion).value = '';
        (this.categoryEntity as InputSuggestion).current = { id: 0, value: '' };

        this.$emit('select-category', { selected, rowId });
    }

    created() {
        const entity = new InputSuggestionEntity('category');
        entity.setPlaceholder('Категория');

        this.categoryEntity = entity.entity[entity.entityName];
    }
}
