
import { Vue, Component } from 'vue-property-decorator';
import ImportEntity from '@/admin/store/settings/import/entity';
import SettingsModule from '@/admin/store/settings';

import Loading from '@/components/Loading.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import InputBlock from '@/components/form/InputMain.vue';
import SelectCustom from '@/components/select/SelectCustom.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import CheckboxDefault from '@/components/form/Checkbox.vue';
import { SelectItem } from '@/lib/formFactory/select.interface';
import DatePicker from '@/components/form/DatePicker.vue';

import TableBlock from './Table.vue';

import Inputmask from 'inputmask';
import moment from 'moment';
import { formatNumberSpaced } from '@/lib/utils/Utils';
import { numberRegExp } from '@/constants';

@Component({
    components: {
        Loading,
        TitleReturn,
        TabsNav,
        InputBlock,
        SelectCustom,
        BtnSubmit,
        CheckboxDefault,
        DatePicker,
        TableBlock,
    },
})
export default class SettingsImportComponent extends Vue {
    title = 'Импорт с сервера';
    componentCounter = 0;

    get model() {
        return ImportEntity.model;
    }

    get isLoading() {
        return ImportEntity.isLoading;
    }

    get tabsNav() {
        return SettingsModule.tabsNav;
    }

    get currentTab() {
        return SettingsModule.allTabs.import.id;
    }

    updateType(type: SelectItem) {
        ImportEntity.updateType(type);
    }

    updateFile(file: SelectItem) {
        ImportEntity.updateFile(file);
    }

    updateDateFrom(params: { day: string; month: string; year: string }) {
        const dateFormatted = moment(`${params.day}/${params.month}/${params.year}`, 'DD/MM/YYYY').format('DD/MM/YY');
        ImportEntity.updateDateFrom(dateFormatted);
    }

    updateDateTo(params: { day: string; month: string; year: string }) {
        const dateFormatted = moment(`${params.day}/${params.month}/${params.year}`, 'DD/MM/YYYY').format('DD/MM/YY');
        ImportEntity.updateDateTo(dateFormatted);
    }

    updateAuctionId(id: string) {
        ImportEntity.updateAuctionId(formatNumberSpaced(id));
    }

    updatePublishPlaced(params: { name: string; id: number; bool: boolean }) {
        ImportEntity.updatePublishPlaced(params.bool);
    }

    send() {
        ImportEntity.sendCreate();
    }

    created() {
        SettingsModule.initTabsItems();
        ImportEntity.init(this.$route.name === 'settings_import_card' ? +this.$route.params.importId : null);
    }

    mounted() {
        const settingsMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputsNumber = this.$el.getElementsByClassName('j_number_mask_wrapper');

        for (const item of Object.values(inputsNumber)) {
            settingsMask.mask(item.getElementsByTagName('input')[0]);
        }
    }

    beforeDestroy() {
        ImportEntity.reset();
    }
}
