import CheckboxEntity from '@/lib/formFactory/checkboxEntity';
import InputEntity from '@/lib/formFactory/inputEntity';

export default class EntityModel {
    model: any;

    constructor() {
        const nameEntity = new InputEntity('name');
        nameEntity.setTitle('Название специализации');

        const sortEntity = new InputEntity('sort');
        sortEntity.setTitle('Порядок сортировки');

        const isActiveEntity = new CheckboxEntity('isActive');
        isActiveEntity.setList({
            0: {
                id: 1,
                name: 'Активен',
                checked: false,
            },
        });

        this.model = {
            name: nameEntity.entity[nameEntity.entityName],
            sort: sortEntity.entity[sortEntity.entityName],
            isActive: isActiveEntity.entity[isActiveEntity.entityName],
            createdAt: '',
        };
    }
}
