import { makeDeleteRequest, makeFormRequest, makeGetRequest } from '@/api/helpers';
import API from './../config/api-routers';

export const createImportRequest = async (data: any) => {
    const result = await makeFormRequest(API.import.create, data);

    return result;
};

export const getParsersTypes = async () => {
    const result = await makeGetRequest(API.import.types);

    return result.data.result.table;
};

export const getParserFiles = async (type: string) => {
    const result = await makeGetRequest(`${API.import.files}?type=${type}`);

    return result.data.result.table;
};

export const getImportsTable = async (currentPage: number, sort: string, filter: string, items: number) => {
    const result = await makeGetRequest(`${API.import.list}?page=${currentPage}&${sort}${filter}&items=${items}`);

    return result.data.result.table;
};

export const getImportCard = async (id: number) => {
    const result = await makeGetRequest(`${API.import.card}${id}`);

    return result.data.result.item;
};

export const deleteImportCard = async (id: number) => {
    const result = await makeDeleteRequest(`${API.import.card}${id}/`);

    return result;
};

export const getImportStatuses = async () => {
    const result = await makeGetRequest(API.import.statuses);

    return result.data.result.table;
};
