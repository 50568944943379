import { TableStepInterface } from '@/lib/layouts/page/table.interface';
import { Component, Vue } from 'vue-property-decorator';
@Component
class CardMixin extends Vue {
    table: { rows: TableStepInterface[] } = {
        rows: [],
    };
    titlesTable = [
        {
            id: 'from',
            name: 'От',
            visible: true,
        },
        {
            id: 'to',
            name: 'До',
            visible: true,
        },
        {
            id: 'fix',
            name: 'Фиксированная цена',
            visible: true,
        },
        {
            id: 'percent',
            name: '+ % от цены лота',
            visible: true,
        },
    ];

    titlesTablePainter = [
        {
            id: 'category',
            name: 'Название категории',
            visible: true,
        },
        {
            id: 'startPrice',
            name: 'Стартовая цена',
            visible: true,
        },
        {
            id: 'maxPrice',
            name: 'Максимальная цена',
            visible: true,
        },
        {
            id: 'priceBuyNow',
            name: 'Цена купить сейчас',
            visible: true,
        },
        {
            id: 'fix',
            name: 'Фиксированная цена',
            visible: true,
        },
        {
            id: 'percent',
            name: 'Шаг % от старт. цен',
            visible: true,
        },
    ];

    depend = 1;

    get saveCounter() {
        return this.$store.state.settingAuction.saveCounter;
    }

    deleteItem(params: any) {
        let deletedStepFromValue: number | string = 0;
        const rows = this.table.rows.reduce(
            (resultRows: TableStepInterface[], current: TableStepInterface, index: number) => {
                if (current.id === params.id) {
                    deletedStepFromValue = current.from;

                    return resultRows;
                }

                if (deletedStepFromValue) {
                    current.from = deletedStepFromValue;
                    deletedStepFromValue = 0;
                }

                current.id = index;
                resultRows.push(current);

                return resultRows;
            },
            [],
        );

        this.table.rows = rows;

        if (!rows.length) {
            this.addEmptyRow();
        }
    }

    handleInput(params: any) {
        console.warn("'handleInput' method is not set for current template", params);
    }

    handleCheckbox(params: { action: string; data: { fieldName: string; rowId: number; bool: boolean } }) {
        console.warn("'handleCheckbox' method is not set for current template", params);
    }

    tableActionHandler(params: any) {
        if (params.action === 'delete') {
            this.deleteItem(params);
        }

        if (params.action === 'input') {
            this.handleInput(params);
        }

        if (params.action === 'check') {
            this.handleCheckbox(params);
        }
    }

    addEmptyRow() {
        console.warn("'addEmptyRow' method is not set for current template");
    }

    findName(name: string) {
        const find = this.$store.state.settingAuction.auctions.find((item: any) => {
            return item.name === name;
        });

        return Object.assign({}, find);
    }

    findJsonName(name: string) {
        const find = this.findName(name);

        find.type = 'json';
        find.value = JSON.parse(find.value);

        return find;
    }

    validatePercentInput(item: { value: number }): void {
        if (+item.value < 0) {
            item.value = 0;
        }

        if (+item.value > 100) {
            item.value = 100;
        }
    }

    validateTableSecond({ value, item }: { value: any; item: any }): void {
        if (+value.to <= value.from + 1) {
            value.to = +value.from + 1;
        }

        if (+value.maxPrice <= +value.startPrice + 1) {
            value.maxPrice = +value.startPrice + 1;
        }

        for (const key in value) {
            if (+value[key] < 0) {
                value[key] = 0;
            }
        }

        if (value.fix.value < 0) {
            value.fix.value = 0;
        }

        if (value.percent.value < 0) {
            value.percent.value = 0;
        }

        if (item.data_value === 'fix') {
            value.fix.checked = true;
            value.percent.checked = false;
        }

        if (item.data_value === 'percent') {
            value.fix.checked = false;
            value.percent.checked = true;
        }

        this.depend++;
    }
}

export default CardMixin;
