
import { Vue, Component } from 'vue-property-decorator';

import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';

import SettingsModule from '@/admin/store/settings';

@Component({
    components: {
        TabsNav,
        TitleReturn,
    },
})
export default class SettingsCurrenciesComponent extends Vue {
    title = 'Валюты';

    get tabsNav() {
        return SettingsModule.tabsNav;
    }

    get currentTab() {
        return SettingsModule.allTabs.currency.id;
    }

    mounted() {
        SettingsModule.initTabsItems();
    }
}
