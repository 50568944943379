
import { Mixins, Component, Watch, Prop } from 'vue-property-decorator';

import TableTitle from './_tableTitleAdd.vue';
import IconFont from '@/components/icons/IconFont.vue';
import PaginationBlock from '@/components/Pagination.vue';
import SimpleRadioBtn from '@/components/form/SimpleRadioBtn.vue';
import AddIcon from '@/components/icons/Add.vue';
import InfinityIcon from '@/components/icons/Infinity.vue';

import tableMixin from './tableMixin';

import moment from 'moment/moment.js';
import Inputmask from 'inputmask';
import { numberRegExp } from '@/constants';

moment.locale('ru');

@Component({
    components: {
        TableTitle,
        IconFont,
        PaginationBlock,
        SimpleRadioBtn,
        AddIcon,
        InfinityIcon,
    },
})
export default class TableInputs extends Mixins(tableMixin) {
    @Prop({ required: false }) readonly isAddHidden!: boolean;

    get rowsLength() {
        return Object.values(this.rows).length;
    }

    @Watch('rowsLength', { immediate: true })
    async onRowsLengthChange() {
        await this.$nextTick();
        this.initMask();
    }

    add() {
        this.$emit('add');
    }

    initMask() {
        const settingsMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputsNumber = this.$el.getElementsByClassName('j_number_mask_wrapper');

        for (const item of Object.values(inputsNumber) as HTMLElement[]) {
            settingsMask.mask(item);
        }
    }

    inputHandler(name: string, id: string | number, data: any) {
        this.$emit('input', { action: name, id, data: data.target.value });
    }

    changeHandler(name: string, id: string | number, data: any) {
        this.$emit('change', { action: name, id, data: data.target.value });
    }

    focusoutHandler(name: string, id: string | number, data: any): void {
        this.$emit('focusout', { action: name, id, data: data.target.value });
    }
}
