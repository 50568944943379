
import { Component } from 'vue-property-decorator';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';

import BtnDefault from '@/components/BtnDefault.vue';
import InputBlock from '@/components/form/InputMain.vue';
import InputLocalization from '@/components/input/InputLocalization.vue';
import LocalizationLayout from '@/admin/layouts/LocalizationLayout.vue';
import SelectCustom from '@/components/select/SelectCustom.vue';
import ImageBlock from '@/components/imageInput/ImageInput.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import Loading from '@/components/Loading.vue';
import CitiesTable from './_citiesTable.vue';

import Inputmask from 'inputmask';
import SettingsCountryModule from '@/admin/store/settings/country/entity';
import RemoveModal from '@/components/modules/removeModal.vue';
import { formatNumberSpaced, formatRemoveSpaces } from '@/lib/utils/Utils';
import { numberRegExp } from '@/constants';
import { LocalizationParams } from '@/interfaces/localization';

@Component({
    components: {
        RemoveModal,
        AppHeaderMain,
        TabsNav,
        TitleReturn,
        BtnDefault,
        InputBlock,
        InputLocalization,
        SelectCustom,
        BtnSubmit,
        ImageBlock,
        Loading,
        CitiesTable,
    },
})
export default class SettingsCountryCard extends LocalizationLayout {
    returnTitleData = {
        icon: '',
        title: '',
    };

    get model() {
        return SettingsCountryModule.model;
    }

    get cardId() {
        return SettingsCountryModule.cardId;
    }

    get isLoading() {
        return SettingsCountryModule.isLoading;
    }

    getFormattedNumberString(value: string): string {
        return formatNumberSpaced(value);
    }

    handleName(params: LocalizationParams): void {
        SettingsCountryModule.updateLocalizationField({ ...params, field: 'name' });
    }

    handleSort(value: string) {
        SettingsCountryModule.updateSort(formatRemoveSpaces(value));
    }

    updatePhoneCode(value: string): void {
        SettingsCountryModule.updatePhoneCode(value);
    }

    updatePhoneMask(value: string): void {
        SettingsCountryModule.updatePhoneMask(value);
    }

    updateIso(value: string): void {
        SettingsCountryModule.updateIso(value);
    }

    returnBefore() {
        this.$router.push({ name: 'settings_countries' });
    }

    send() {
        if (this.$route.params.countryId) {
            this.sendEdit();

            return;
        }
        this.sendAdd();
    }

    sendEdit() {
        SettingsCountryModule.sendEdit();
    }

    sendAdd() {
        SettingsCountryModule.sendAdd({ name: 'settings_countries' });
    }

    initMask() {
        const settingsMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputsNumber = document.getElementsByClassName('j_number_mask_wrapper');

        const phoneCodeMask = new Inputmask({ regex: '\\+\\d+', showMaskOnHover: false });
        const inputsPhoneCode = document.getElementsByClassName('j_phone_code_mask_wrapper');

        const isoMask = new Inputmask({ regex: '[a-zA-Z]+', showMaskOnHover: false });
        const inputsIso = document.getElementsByClassName('j_iso_mask_wrapper');

        for (const item of Object.values(inputsNumber)) {
            settingsMask.mask(item.getElementsByTagName('input')[0]);
        }

        for (const item of Object.values(inputsPhoneCode)) {
            phoneCodeMask.mask(item.getElementsByTagName('input')[0]);
        }

        for (const item of Object.values(inputsIso)) {
            isoMask.mask(item.getElementsByTagName('input')[0]);
        }
    }

    async mounted(): Promise<void> {
        this.initMask();
        if (this.$route.params.countryId) {
            await SettingsCountryModule.initCardById(this.$route.params.countryId);
            SettingsCountryModule.updateIsLoading(false);
            this.returnTitleData = SettingsCountryModule.titleReturnData.edit;

            return;
        }

        SettingsCountryModule.updateIsLoading(false);
        this.returnTitleData = SettingsCountryModule.titleReturnData.create;
    }

    beforeDestroy(): void {
        SettingsCountryModule.reset();
    }
}
