
import { Component, Vue } from 'vue-property-decorator';
import FlexLayout from '@/admin/layouts/FlexLayout.vue';
import Loading from '@/components/Loading.vue';
import TableBlock from '@/components/table/Table.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';

// import FilterModule from '@/admin/store/filter';
import SettingsModule from '@/admin/store/settings';
import SettingsCategoryModule from '@/admin/store/settings/category';

@Component({
    components: {
        Loading,
        FlexLayout,
        TableBlock,
        AppHeaderMain,
        TabsNav,
        TitleReturn,
    },
})
export default class SettingsCityMain extends Vue {
    get settings() {
        return SettingsCategoryModule.pageSettings;
    }

    get filterSettings() {
        return SettingsCategoryModule.filter.filterSettings;
    }

    get isLoading(): boolean {
        return SettingsCategoryModule.isLoading;
    }

    get tabsNav() {
        return SettingsModule.tabsNav;
    }

    get currentTab() {
        return SettingsModule.allTabs.categories.id;
    }

    get paginationAction() {
        return SettingsCategoryModule.updatePage;
    }

    get paginationItems(): { action: (value: string | number) => void; items: string } {
        return {
            action: SettingsCategoryModule.updateItems,
            items: SettingsCategoryModule.pageSettings.pageAmountItems,
        };
    }

    tableAction(params: any) {
        if (params.action === 'sort') {
            SettingsCategoryModule.sort({ field: params.field, sort: params.sort });
        }
    }

    updateList() {
        SettingsCategoryModule.getList();
    }

    async resetFilters() {
        await SettingsCategoryModule.clearSort();
        await SettingsCategoryModule.filter.resetFilter();
        SettingsCategoryModule.getList();
    }

    toAdd() {
        this.$router.push({ name: 'settings_category_create' });
    }

    mounted(): void {
        SettingsCategoryModule.init();
        SettingsModule.initTabsItems();
    }

    beforeDestroy(): void {
        SettingsCategoryModule.reset();
    }
}
