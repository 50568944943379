import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';

import PageBaseModule from '@/admin/store/page';
import store from '@/admin/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';
import { getCategoryAll, getCategoryList } from '@/admin/api/category';

import filterModel from './filter';
import SettingsCategoryFilter from './filterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { FilterInputSuggestionsParamsInterface } from '@/lib/layouts/page/filterParams.interface';
import { processListForSelect } from '@/lib/utils/form';
import { generateSort } from '@/lib/utils/Utils';

export const MODULE_NAME = 'settingsCategory';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SettingsCategoryModule extends PageBaseModule {
    filter: Filter;

    constructor(module: SettingsCategoryModule) {
        super(module);
        const page = new PageEntity();

        page.setTitle('Категории сайта');
        page.setSortName('settingsCategorySort');
        page.setDefaultSortField('name');
        this.pageSettings = page.values;

        const filter = new filterModel();
        this.filter = getModule(SettingsCategoryFilter);
        this.filter.setTemplateClassName('template-sm');
        this.filter.setBtnClassName('row-5');
        this.filter.setFilterName('settingsCategoryFilter');
        this.filter.setTemplateClassName('template-sm');
        this.filter.setFilterModel(filter.filterModel);
        this.filter.setFilterHandlers(filter.filterHandlers);
    }

    @Mutation
    SET_PAGE(number: number) {
        this.pageSettings.pageCurrent = number;
    }

    @Action({ rawError: true })
    async init() {
        this.setDefaultSort();
        this.SET_FILTER_REQUEST_SORT(await generateSort({ id: 'name', value: 'ASC' }));

        this.filter.init();

        this.getList();
    }

    @Action({ rawError: true })
    async getList() {
        this.SET_IS_LOADING(true);
        const sort = await this.getSortForRequest();
        const result = await getCategoryList(
            this.pageSettings.pageCurrent,
            sort,
            this.filter.filterSettings.filter,
            this.pageSettings.pageAmountItems,
        );

        await this.setList(result);
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async setList(table: TableApiInterface) {
        const tableProcessed = await this.prepareTable(table);

        this.context.commit('SET_TABLE', tableProcessed);
    }

    @Action({ rawError: true })
    prepareTable(table: TableApiInterface) {
        const hiddenColumns = Object.values(table.titles)
            .filter((title) => title.visible === false)
            .map((title) => title.id);

        const rows: any = [];

        let index: string;
        let row: any;
        for ([index, row] of Object.entries(table.rows)) {
            const rowProcessed: any = {};
            for (const i of Object.keys(row)) {
                let cell: any = row[i];

                if (i === 'name') {
                    cell = `<span class="cp-table-column__accent">${cell}</span>`;
                }

                if (i === 'photo') {
                    cell = cell ? `<img src="${cell}" width="80" class="cp-input-radius-25" />` : '';
                }

                rowProcessed[i] = cell;
            }

            rows[index] = rowProcessed;
        }

        return {
            titles: table.titles,
            rows: rows,
            sortFields: table.sortFields,
            pagination: table.pagination ? table.pagination : {},
            totalItems: table.totalItems,
            filters: table.filters,
            hiddenColumns: hiddenColumns,
        } as TableInterface;
    }

    @Action({ rawError: true })
    async initCategory() {
        const result = await getCategoryAll();
        const categories = result.map((item: any) => {
            return {
                id: item.id,
                value: item.name,
            };
        });

        return categories;
    }

    @Action({ rawError: true })
    updateSuggestionsSelectedFilter(params: FilterInputSuggestionsParamsInterface) {
        params = { ...params, value: { id: params?.value?.value || '', value: params?.value?.value || '' } };
        this.filter.updateSuggestionsSelected(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateSuggestionsSearchFilter(params: { key: string; value: string }) {
        const filter = params.value ? `&filters[0][id]=name&filters[0][value]=${params.value}` : '';
        this.filter.updateSuggestionsValue(params);

        const table = await getCategoryList(1, this.pageSettings.filterRequestSort ?? '', filter, 50);
        const list = table ? Object.values(table.rows) : [];

        this.filter.updateSuggestionsHintsList({ key: params.key, value: processListForSelect(list) });
    }
}

export default getModule(SettingsCategoryModule);
