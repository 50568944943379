import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import EntityBaseModule from '@/admin/store/entity';
import EntityModel from './entityModel';

import store from '@/admin/store';
import { createCountryCard, editCountryCard, getCountryCard } from '@/admin/api/country';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import RedirectHandlerModule from '@/admin/store/modules/redirectHandler';
import { formatCreatedAt, formatRemoveSpaces, getApiErrorMessage } from '@/lib/utils/Utils';
import SiteModule from '@/admin/store/site';
import { CountryModelInterface } from '@/interfaces/models/country.interface';
import { prepareLocalization, processLocalizationFromBackend } from '@/lib/utils/localization';

export const MODULE_NAME = 'settingsCountryEntity';

export interface CountryRequestDataInterface {
    name: string;
    sort?: string;
    phoneCode?: string;
    phoneMask?: string;
    iso?: string;
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SettingsCountryModule extends EntityBaseModule {
    cardId: number | null = null;
    model: CountryModelInterface;
    countryName = '';
    selectDefault = { id: 0, value: '' };
    titleReturnData = {
        edit: {
            icon: 'compass',
            title: this.countryName,
        },
        create: {
            icon: '',
            title: 'Добавление страны',
        },
    };

    constructor(module: SettingsCountryModule) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
    }

    @Mutation
    SET_CARD_ID(id: number | null) {
        this.cardId = id;
    }

    @Mutation
    SET_NAME(value: string) {
        this.model.name.value = value;
    }

    @Mutation
    SET_COUNTRY_NAME(name: string) {
        this.countryName = name;
        this.titleReturnData.edit.title = name;
    }

    @Action({ rawError: true })
    async initCardById(countryId: number | string) {
        const result = await getCountryCard(countryId);

        await this.setInfo(result);
    }

    @Action({ rawError: true })
    setInfo(data: any) {
        if (!data) {
            return;
        }

        this.context.commit('SET_CARD_ID', data.id);
        this.SET_MODEL_FIELD_VALUE({ field: 'name', value: data.name ?? '' });
        this.SET_MODEL_FIELD_VALUE({ field: 'sort', value: data.sort ?? '' });
        this.SET_MODEL_FIELD_VALUE({ field: 'phoneCode', value: data.phoneCode ?? '' });
        this.SET_MODEL_FIELD_VALUE({ field: 'phoneMask', value: data.phoneMask ?? '' });
        this.SET_MODEL_FIELD_VALUE({ field: 'iso', value: data.iso ?? '' });
        this.context.commit('SET_CREATED_AT', formatCreatedAt(data.createdAt));

        const localization = processLocalizationFromBackend(data, 'name');
        this.SET_LOCALIZATION_BY_FIELD({ fieldName: 'name', data: localization });
    }

    @Action({ rawError: true })
    updateName(value: string) {
        if (value !== '') {
            this.context.commit('RESET_ERROR_BY_FIELD', 'name');
        }
        this.context.commit('SET_NAME', value);
    }

    @Action({ rawError: true })
    updateSort(sort: string) {
        this.SET_SORT_ORDER(sort);
    }

    @Action({ rawError: true })
    updatePhoneCode(value: string) {
        if (value.length && value.indexOf('+') !== 0) {
            value = `+${value}`;
        }

        this.SET_MODEL_FIELD_VALUE({ field: 'phoneCode', value });
    }

    @Action({ rawError: true })
    updatePhoneMask(value: string) {
        value = value.replace(/[A-Za-z0-9]/g, '9').replace(/-+/g, '-');
        this.SET_MODEL_FIELD_VALUE({ field: 'phoneMask', value });
    }

    @Action({ rawError: true })
    updateIso(value: string) {
        this.SET_MODEL_FIELD_VALUE({ field: 'iso', value: value.toUpperCase() });
    }

    @Action({ rawError: true })
    validate() {
        let isError = false;
        this.context.commit('RESET_ERROR_BY_FIELD', 'name');

        this.setValidateState({ index: 'name', field: this.model.name });

        if (!this.model.name.value) {
            isError = true;
        }

        return isError;
    }

    @Action({ rawError: true })
    prepareRequestData() {
        let data: Record<string, string> = {
            name: this.model.name.localization?.ru || '',
        };

        data = { ...data, ...prepareLocalization(this.model.name, 'name') };

        if (this.model.sort.value) {
            data['sort'] = formatRemoveSpaces(this.model.sort.value);
        }

        if (this.model.phoneMask.value.length) {
            data['phoneMask'] = this.model.phoneMask.value;
        }

        if (this.model.phoneCode.value.length) {
            data['phoneCode'] = this.model.phoneCode.value;
        }

        if (this.model.iso.value.length) {
            data['iso'] = this.model.iso.value;
        }

        return data;
    }

    @Action({ rawError: true })
    async sendEdit() {
        const isError = await this.validate();

        if (isError === true) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const formData = await this.prepareRequestData();
            let result: any;

            if (this.cardId != null) {
                result = await editCountryCard(this.cardId, formData);
            }

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }

            SiteModule.SET_IS_BLOCK(false);
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
            SiteModule.SET_IS_BLOCK(false);
        }
    }

    @Action({ rawError: true })
    async sendAdd(redirect: any = null) {
        const isError = await this.validate();

        if (isError === true) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const formData = await this.prepareRequestData();
            const result: any = await createCountryCard(formData);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                RedirectHandlerModule.changeUrl(redirect);
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }

            SiteModule.SET_IS_BLOCK(false);
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
            SiteModule.SET_IS_BLOCK(false);
        }
    }

    @Action({ rawError: true })
    reset() {
        this.context.commit('SET_IS_LOADING', true);
        this.context.commit('SET_CARD_ID', null);
        this.context.commit('SET_COUNTRY_NAME', '');
        this.SET_MODEL_FIELD_VALUE({ field: 'name' });
        this.SET_MODEL_FIELD_VALUE({ field: 'sort' });
        this.SET_MODEL_FIELD_VALUE({ field: 'phoneCode' });
        this.SET_MODEL_FIELD_VALUE({ field: 'phoneMask' });
        this.SET_MODEL_FIELD_VALUE({ field: 'iso' });
        this.RESET_LOCALIZATION_BY_FIELD();

        this.context.commit('RESET_ERROR_BY_FIELD', 'name');

        SiteModule.SET_IS_BLOCK(false);
    }
}

export default getModule(SettingsCountryModule);
