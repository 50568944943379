
import { Component, Vue, Prop } from 'vue-property-decorator';

import Common from './card/Common.vue';
import RulesActionHouse from './card/RulesActionHouse.vue';
import RulesPainter from './card/RulesPainter.vue';
import RulesGallery from './card/RulesGallery.vue';

import SettingsActionModule from '@/admin/store/settings/auctions';
import SettingsPainterModule from '@/admin/store/settings/auctions/painter';
import SettingsGalleryModule from '@/admin/store/settings/auctions/gallery';
import { editSettingAuction } from '@/admin/api/settingAuction';

import ResponseHandlerModule from '@/admin/store/modules/responseHandler';

import Loading from '@/components/Loading.vue';

@Component({
    components: {
        Common,
        RulesActionHouse,
        RulesPainter,
        RulesGallery,
        Loading,
    },
})
export default class SettingsAuctionApp extends Vue {
    @Prop() categories: unknown;

    loading = false;

    async setSaveCounter(): Promise<void> {
        this.loading = true;

        await SettingsActionModule.setSaveCounter();
        await SettingsPainterModule.setSaveCounter();
        await SettingsGalleryModule.setSaveCounter();

        let auctionsSettings = SettingsActionModule.auctions;
        let paintersSettings = SettingsPainterModule.settings;
        let painterProductivity = SettingsPainterModule.productivity;
        let gallerySettings = SettingsGalleryModule.settings;

        await auctionsSettings.forEach(async (item: any, i: number) => {
            await editSettingAuction('CommonAuction', item.name, `?value=${item.value}`);

            if (i === auctionsSettings.length - 1) {
                this.loading = false;

                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            }
        });

        await paintersSettings.forEach(async (item: any, i: number) => {
            await editSettingAuction('CommonPainter', item.name, `?value=${item.value}`);

            if (i === paintersSettings.length - 1) {
                this.loading = false;

                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            }
        });

        await gallerySettings.forEach(async (item: any, i: number) => {
            await editSettingAuction('CommonGallery', item.name, `?value=${item.value}`);

            if (i === paintersSettings.length - 1) {
                this.loading = false;

                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            }
        });

        const productivity: { id: number; from: number; to?: number }[] = [];

        Object.values(painterProductivity).forEach((item, index) => {
            productivity[index] = { id: index, from: item.range.from };

            if (item.range.to) {
                productivity[index].to = item.range.to;
            }

            editSettingAuction('CommonPainter', `limit.${index}`, `?value=${JSON.stringify(item.limit)}`);
            editSettingAuction('CommonPainter', `reExposeTime.${index}`, `?value=${JSON.stringify(item.reExposeTime)}`);

            editSettingAuction(
                'CommonPainter',
                `categories.${index}`,
                `?value=${JSON.stringify(
                    item.categories.length === 1 && !item.categories[0].category.id ? [] : item.categories,
                )}`,
            );
        });

        await editSettingAuction('CommonPainter', 'productivity', `?value=${JSON.stringify(productivity)}`);
    }

    reloadPage(): void {
        document.location.reload();
    }
}
